import { Alert, Button, Card, Skeleton } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import Print from "modules/master-data/consignment/return-order/print";
import InternalReturnOrderTableView from "modules/master-data/internal-return-order/table-view";
import { useRef } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import internalReturnOrderService from "services/api-endpoints/dashboard/master-data/internal-return-order";
import { RETURN_ORDER_STATUS } from "utils/constant";

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const CReturnOrderView = ({ paramId, asPreview }: Props) => {
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");

    const getHeader = useQuery([internalReturnOrderService.header], async () => {
        return (await internalReturnOrderService.Header()).data.data;
    });

    const detailQuery = useQuery(
        [internalReturnOrderService.getDetailReturnOrder, id],
        async () => {
            return (await internalReturnOrderService.GetDetailReturnOrder(id)).data.data;
        },
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
            enabled: !!id,
        }
    );

    const letterRef = useRef<HTMLDivElement | null>(null);
    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `Return Order - ${detailQuery.data?.doc_no}`,
        pageStyle: "@page { }",
    });

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <Print ref={letterRef} data={detailQuery.data || null} />
            <ToolbarAction
                title="detail return order"
                rightAddition={() => (
                    <>
                        <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                            <AiFillPrinter className="m-0 mr-2" />
                            Print
                        </Button>
                    </>
                )}
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Card className="">
                                <div className="grid grid-cols-4 gap-x-5">
                                    <p className="capitalize m-0">
                                        Document no <br />
                                        <span className="italic m-0">{detailQuery.data?.doc_no}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Referensi no <br />
                                        <span className="italic m-0">{detailQuery.data?.ref_no}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Return To <br />
                                        <span className="italic m-0">{getHeader.data?.parent_name || "-"}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Reason to return <br />
                                        <span className="italic m-0">{detailQuery.data?.reason}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Status <br />
                                        <span className="italic m-0">
                                            {RETURN_ORDER_STATUS.find((status) => status.value === detailQuery.data?.order_status)?.label}
                                        </span>
                                    </p>
                                </div>
                            </Card>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 1 }} active />
                            <Skeleton paragraph={{ rows: 1 }} active />
                            <Skeleton paragraph={{ rows: 1 }} active />
                            <Skeleton paragraph={{ rows: 1 }} active />
                        </State.Loading>
                        <State.Error state={state}>
                            <Alert type="error" message={(detailQuery.error as any)?.message} />
                        </State.Error>
                    </>
                )}
            </State>
            <InternalReturnOrderTableView fetcher={detailQuery} />
        </div>
    );
};

export default CReturnOrderView;
