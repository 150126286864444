import { Button, InputNumber, Table, Transfer } from "antd";
import type { ColumnsType, TableRowSelection } from "antd/es/table/interface";
import type { TransferItem, TransferProps } from "antd/es/transfer";
import difference from "lodash/difference";
import LocatorModal from "modules/master-data/consignment/modal/locator-modal";
import LocatorModalWorkingOrder from "modules/master-data/consignment/modal/locator-modal-working-order";
import LocatorModalMM from "modules/master-data/material-movement-doc/add/modal-locator-mm";
import React from "react";
import { LocatorData } from "services/api-endpoints/dashboard/master-data/c-picking-detail";
import { ProductTransferWithEdit } from "services/api-endpoints/dashboard/master-data/material-movement";
import { B_TYPE_AII, B_TYPE_SPM, TYPE_MM_INTERNAL_PO, TYPE_MM_RETURN_EXTERNAL, TYPE_MM_RETURN_INTERNAL, TYPE_MM_WORKING_ORDER } from "utils/constant";

interface TableTransferProps<T> extends TransferProps<T> {
    dataSource: T[];
    loading?: boolean;
    editList?: any[];
    setEditList?: React.Dispatch<React.SetStateAction<any[]>>;
    children: (data: any) => React.ReactNode;
    setProducts?: React.Dispatch<React.SetStateAction<ProductTransferWithEdit[]>>;
    onSubmitLocator: (docId: number, locator: LocatorData) => void;
    option?: number;
    tableColumnLeft?: (baseColumn: ColumnsType<ProductTransferWithEdit>) => ColumnsType<T>;
    bTypeId?: number;
    warehouseId?: number;
}

const TableTransferByDoc = <T extends TransferItem>({
    loading,
    editList,
    setEditList,
    children,
    onSubmitLocator,
    setProducts,
    option,
    tableColumnLeft,
    bTypeId,
    warehouseId,
    ...restProps
}: TableTransferProps<T>) => {
    const leftColumns: ColumnsType<ProductTransferWithEdit> = [
        {
            dataIndex: "area_name",
            title: "Locator",
            render: (text, record) => (
                <>
                    {option === TYPE_MM_INTERNAL_PO && (
                        <LocatorModalMM
                            title="Locator"
                            docId={record.id!}
                            productId={record.product_id}
                            warehouseId={warehouseId}
                            onSubmit={onSubmitLocator}
                        >
                            {(dt) => (
                                <Button
                                    size="small"
                                    onClick={() => dt.openModalWithData(record)}
                                    className="!flex !items-center"
                                    type="default"
                                    {...(!text ? { danger: true } : {})}
                                >
                                    {text || "Choose Locator"}
                                </Button>
                            )}
                        </LocatorModalMM>
                    )}
                    {(option === TYPE_MM_RETURN_INTERNAL || option === TYPE_MM_RETURN_EXTERNAL) && (
                        <LocatorModal title="Locator" docId={record.id!} productId={record.product_id} onSubmit={onSubmitLocator}>
                            {(dt) => (
                                <Button
                                    size="small"
                                    onClick={() => dt.openModalWithData(record)}
                                    className="!flex !items-center"
                                    type="default"
                                    {...(!text ? { danger: true } : {})}
                                >
                                    {text || "Choose Locator"}
                                </Button>
                            )}
                        </LocatorModal>
                    )}
                    {option === TYPE_MM_WORKING_ORDER && (
                        <LocatorModalWorkingOrder title="Locator" docId={record.id!} productId={record.product_id} onSubmit={onSubmitLocator}>
                            {(dt) => (
                                <Button
                                    size="small"
                                    onClick={() => dt.openModalWithData(record)}
                                    className="!flex !items-center"
                                    type="default"
                                    {...(!text ? { danger: true } : {})}
                                >
                                    {text || "Choose Locator"}
                                </Button>
                            )}
                        </LocatorModalWorkingOrder>
                    )}
                </>
            ),
        },
        {
            width: "70px",
            dataIndex: "qty_locator",
            title: "Loc Qty",
            render: (text, rec) => `${text || "-"} ${rec?.uom_name || ""}`,
        },
        {
            dataIndex: "product_code",
            title: "Code",
            render: (text) => text,
        },
        {
            dataIndex: "product_name",
            title: "Name",
            render: (text) => text,
        },
        {
            dataIndex: "qty_from",
            title: "Qty",
            render: (text) => text,
        },
    ];

    const leftColumns2: ColumnsType<ProductTransferWithEdit> = [
        {
            dataIndex: "product_code",
            title: "Code",
            render: (text) => text,
        },
        {
            dataIndex: "product_name",
            title: "Name",
            render: (text) => text,
        },
        {
            dataIndex: "qty_from",
            title: "Qty",
            render: (text) => text,
        },
    ];

    const tableColumnFinal = () => {
        if (tableColumnLeft) return tableColumnLeft(leftColumns);
        if (
            option === TYPE_MM_RETURN_INTERNAL ||
            option === TYPE_MM_RETURN_EXTERNAL ||
            option === TYPE_MM_WORKING_ORDER ||
            (option === TYPE_MM_INTERNAL_PO && bTypeId === B_TYPE_AII)
        )
            return leftColumns;
        return leftColumns2;
    };

    return (
        <Transfer {...restProps}>
            {(action) => {
                const rowSelection: TableRowSelection<TransferItem> = {
                    getCheckboxProps: (item) => ({
                        disabled:
                            action.disabled ||
                            item.disabled ||
                            ((option === TYPE_MM_WORKING_ORDER ||
                                option === TYPE_MM_RETURN_EXTERNAL ||
                                option === TYPE_MM_RETURN_INTERNAL ||
                                (option === TYPE_MM_INTERNAL_PO && bTypeId === B_TYPE_AII)) &&
                                !item?.qty_locator) ||
                            (action.direction === "left" &&
                                (option === TYPE_MM_WORKING_ORDER ||
                                    option === TYPE_MM_RETURN_EXTERNAL ||
                                    option === TYPE_MM_RETURN_INTERNAL ||
                                    (option === TYPE_MM_INTERNAL_PO && bTypeId === B_TYPE_AII)) &&
                                item?.qty_locator < item?.qty_from),
                    }),
                    onSelectAll(selected, selectedRows) {
                        const treeSelectedKeys = selectedRows.filter((item) => !item.disabled).map(({ key }) => key);
                        const diffKeys = selected
                            ? difference(treeSelectedKeys, action.selectedKeys)
                            : difference(action.selectedKeys, treeSelectedKeys);
                        action.onItemSelectAll(diffKeys as string[], selected);
                    },
                    onSelect({ key }, selected) {
                        action.onItemSelect(key as string, selected);
                    },
                    selectedRowKeys: action.selectedKeys,
                };

                if (action.direction === "left") {
                    return (
                        <Table
                            rowSelection={rowSelection}
                            columns={tableColumnFinal() as any}
                            dataSource={action.filteredItems}
                            size="small"
                            loading={loading}
                            style={{ pointerEvents: action.disabled ? "none" : undefined }}
                        />
                    );
                }

                return children({ ...action, rowSelection });
            }}
        </Transfer>
    );
};
export default TableTransferByDoc;
