import { Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import ButtonAccessView from "components/button/button-access-view";
import moment from "moment";
import { useState } from "react";
import { UseQueryResult } from "react-query";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { CashierTransaksiList } from "services/api-endpoints/cashier/cashier-transaksi";
import { FORMAT_DATE_TIME, VIEW_PATH } from "utils/constant";

type Props<T> = {
    fetcher: UseQueryResult<T[], unknown>;
};

const TableIndex = <T extends CashierTransaksiList>({ fetcher }: Props<T>) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPage(pagination.current || 1);
    };

    const viewHandler = (data: CashierTransaksiList) => {
        navigate(`${location.pathname + VIEW_PATH}?id=${data.id}`);
    };

    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{(page - 1) * 20 + (i + 1)}</p>,
        },
        {
            title: "Invoice No",
            render: (record) => <p className="capitalize m-0">{record.inv_no || "-"}</p>,
        },
        {
            title: "Down Payment",
            render: (record) => <p className="capitalize m-0">{record.dp || ""}</p>,
        },
        {
            title: "Create Date",
            dataIndex: "created_at",
            render: (text) => <p className="capitalize m-0">{text ? moment(text).format(FORMAT_DATE_TIME) : "-"}</p>,
        },
        {
            width: "100px",
            title: "Action",
            key: "action",
            fixed: "right",
            render: (_, record) => <ButtonAccessView onClick={() => viewHandler(record)} type="text" />,
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            loading={fetcher.isLoading}
            columns={columns}
            dataSource={fetcher.data || []}
            className="w-full"
            pagination={{
                pageSize: 20,
                showSizeChanger: false,
            }}
            onChange={handleTableChange}
        />
    );
};

export default TableIndex;
