/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import { Button } from "antd";
import { ColumnType } from "antd/lib/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React from "react";
import { AiOutlineQrcode } from "react-icons/ai";
import { ProductPOM } from "services/api-endpoints/dashboard/manufacture/working-order-m";
import ModalBarcode from "./modal-barcode";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow"> & {
    id: any;
};

export function addTable<T extends ProductPOM>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ setList, list, id, ...props }: Props<T>) => {
        const [editRow, setEditRow] = React.useState<ProductPOM | null>(null);

        const columns: ColumnType<ProductPOM>[] = [
            {
                title: "No",
                dataIndex: "no",
                render: (t, r, i) => i + 1,
            },
            {
                title: "Product Code",
                dataIndex: "product_code1",
            },
            {
                title: "Quantity",
                dataIndex: "qty",
                ...{ editable: true },
            },
            {
                title: "Barcode",
                render: (val, record) => (
                    <ModalBarcode id={id} productId={record.product_id} productName={record.product_code1}>
                        {(dt) => (
                            <Button onClick={dt.openModal} type="default" className="!flex !items-center">
                                <AiOutlineQrcode className="m-0 mr-2" />
                                Lihat Barcode
                            </Button>
                        )}
                    </ModalBarcode>
                ),
            },
        ];

        const isEditing = (record: ProductPOM, edited: ProductPOM | null) =>
            record.product_id === edited?.product_id && record.product_id === edited.product_id;
        const findIndexSave = (record: ProductPOM, edited: ProductPOM) =>
            record.product_id === edited?.product_id && record.product_id === edited.product_id;
        const rowKey = (record: ProductPOM) => `${record.product_id}_${record?.product_id}`;

        const editInputType: EditTableProps<ProductPOM>["editInputType"] = { qty_sisa: "number" };

        return (
            <Component
                {...props}
                bordered
                cellProps={{
                    minNumber: 1,
                    maxNumber: editRow?.qty_sisa,
                }}
                list={list as any}
                setEditRow={setEditRow as any}
                editRow={editRow as any}
                editInputType={editInputType}
                rowKey={rowKey as any}
                columns={columns as any}
                isEditing={isEditing as any}
                findIndexSave={findIndexSave as any}
            />
        );
    };
}

const TableProductsView = addTable(EditTable);
export default TableProductsView;
