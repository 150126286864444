/* eslint-disable no-useless-constructor */
import * as Models from "models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import { EditMM } from "modules/master-data/material-movement/models";
import { RowDefault } from "components/table/edit-table";

export interface Locator {
    area_id_to: number;
    area_id_from: number;
    area_name: string;
}

export interface LocatorFrom {
    area_id_from?: number;
    area_name_from?: string;
    qty_from?: number;
}

export interface LocatorTo {
    area_id_to?: number;
    area_name_to?: string;
    qty_to?: number;
}

export interface ProductTransform {
    order_id?: number;
    product_id?: number;
    locator_from?: LocatorFrom[];
    locator_to?: LocatorTo[];
}

export interface CreateMM {
    type?: number;
    type_stock_opname: "MATERIAL MOVEMENT";
    warehouse_id_from: number;
    warehouse_id_to: number;
    area_from_id: number;
    note: string;
    option: number;
    order_id: number[] | null;
    product?: ProductTransform[];
}

export interface ProductTransfer {
    id?: any;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    order_id?: number;
    area_id?: any;
    area_name?: string | null;
    qty_from?: number;
    qty_to?: any;
    qty_approved?: number;
    locator_to?: any;
    qty_locator?: any;
    uom_id?: any;
    uom_name?: string;
    suggestion_locator?: string;
    random_id?: any;
    qty_use?: any;
}

export interface ProductTransferWithEdit extends ProductTransfer {
    qty_to?: number;
    locator_to?: any;
    order_id?: number;
    disabled?: boolean;
}

export interface ProductTransferWithEdit2 extends RowDefault {
    id?: number;
    product_id?: number;
    product_name?: string;
    product_code?: string;
    area_id?: number;
    area_name?: string | null;
    qty?: number;
    qty_before?: number;
    qty_scan?: number;
}

export interface GetProductParams {
    warehouse_id_to: any;
    area_id: any;
    option: any;
}

export interface GetProduct2Params {
    warehouse_id_from: any;
    area_id_from: any;
    warehouse_id_to: any;
    option: any;
}

export interface GetProductByOrderParams {
    order_id: string;
    option: any;
    warehouse_id_to: any;
}

export interface MaterialMovement {
    created_at: number;
    created_by: string;
    doc_no: string;
    id: number;
    status: number;
    total_move: number | string;
    warehouse_from: string;
    warehouse_to: string;
}

export interface ListProduct {
    product_id?: number;
    product_name?: string;
    product_code?: string;
    order_id?: number;
    list_locator_from?: LocatorFrom[];
    list_locator_to?: LocatorTo[];
}

export interface ListLocator {
    area_id?: number;
    area_name?: string;
    qty?: number;
    qty_before?: number;
    qty_scan?: number;
}

export interface ListProductRoutine {
    product_id?: number;
    product_name?: string;
    product_code?: string;
    order_id?: number;
    list_locator: ListLocator[];
}

export interface MaterialMovementDetail {
    id?: number;
    name?: string;
    address?: string;
    mm_no: string | null;
    note: string;
    option?: number;
    order_id?: number[] | null;
    warehouse_id_from?: number;
    warehouse_id_to?: number;
    warehouse_from?: string;
    warehouse_to?: string;
    area_from_id?: number;
    area_from?: string | undefined | null;
    list_product?: ListProduct[];
    doc_ref_no?: string[];
}

export interface DeleteMM {
    id: number;
    note: string;
}

export interface ByDocData {
    order_id: number;
    doc_no: string;
}

export interface SuggestionData {
    suggestion_locator: string;
}

export interface SuggestionReq {
    productID: number;
    warehouseID: number;
}

export interface WarehouseTo {
    warehouse_id_to: number;
    warehouse_name: string;
    b_type_id: number;
}

export interface WarehouseFrom {
    warehouse_id_from: number;
    warehouse_name: string;
}

export interface HistoryDocMM {
    order_id: number;
    doc_no: string;
}

export interface HistoryMM {
    id: number;
    note: string;
    list_doc: HistoryDocMM[];
    created_by: string;
    created_at: number;
}

export interface DetailHistory {
    order_id?: number;
    po_no?: string;
    do_no?: string;
    so_no?: string;
    request_from?: string;
    request_to?: string;
    list_detail?: any[];
}

export interface ListStock {
    id?: number;
    doc_no?: string;
    warehouse_name?: string;
    total_move?: string;
    status?: number;
    created_by?: string;
    created_at?: number;
}

export interface DetailStock {
    id?: number;
    mm_no?: string;
    note?: string;
    warehouse_id?: number;
    warehouse_name?: string;
    area_id_from?: number;
    area_from?: string;
    created_at?: string;
    order_id?: number;
    status?: number;
    list_product?: ListProductRoutine[];
}

export interface AdjustList {
    id: number;
    area_id: number;
    qty: number;
}

export interface AdjustParams {
    id: string | null;
    warehouse_id: number;
    status: number;
    list_product: AdjustList[];
}

export interface LocatorData {
    area_id?: number;
    area_name?: string;
    product_name?: string;
    product_id?: number;
    qty_locator?: string;
}

class MaterialMovementService extends BaseService {
    getMaterialMovement = "/mm/get-material-movement";

    search = "/mm/search-material-movement";

    getWHFrom = "/mm/get-wh-from";

    getWHTo = "/mm/get-wh-to";

    getLocatorFrom = "/mm/get-locator-from";

    getLocatorTo = "/mm/get-locator-to";

    getProduct = "/mm/get-product";

    create = "/mm/create-material-movement";

    createDraft = "/mm/create-draft";

    detail = "/mm/detail-material-movement";

    edit = "/mm/edit-material-movement";

    editDraft = "/mm/edit-draft";

    delete = "/mm/delete-material-movement";

    byDoc = "/mm/get-order-product";

    productByOrderId = "mm/get-product-order-id";

    suggestionLocator = "mm/get-suggestion-locator";

    historyMM = "mm/get-history-mm";

    historyDetailMM = "mm/get-detail-history";

    getRoutineStock = "mm/get-routine-stock";

    detailRoutineStock = "mm/detail-routine-stock";

    adjustStock = "mm/adjust-routine-stock";

    editCreate = "mm/edit-create";

    getLocator = "mm/get-locator";

    constructor() {
        super();
    }

    GetLocator<T extends LocatorData[]>(param: { product_id: any; warehouse_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getLocator,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    EditCreate<T extends any>(data: EditMM) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editCreate,
                data,
            });
            return req;
        });
    }

    GetRoutineStock<T extends ListStock>(params: { page: any; query: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getRoutineStock,
                config: { params },
            });
            return req;
        });
    }

    DetailRoutineStock<T extends DetailStock>(data: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detailRoutineStock}/${data.id}`,
            });
            return req;
        });
    }

    AdjustStock<T extends any>(data: AdjustParams) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.adjustStock,
                data,
            });
            return req;
        });
    }

    HistoryDetailMM<T extends DetailHistory>(param: { order_id: any; option: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.historyDetailMM,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    HistoryMM<T extends HistoryMM>(param: { page: any; id: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.historyMM,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetWHTo<T extends WarehouseTo[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getWHTo,
            });
            return req;
        });
    }

    GetWHFrom<T extends WarehouseFrom[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getWHFrom,
            });
            return req;
        });
    }

    SuggestionLocator<T extends SuggestionData>(productID: number, warehouseID: number) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.suggestionLocator}/${productID}/${warehouseID}`,
            });
            return req;
        });
    }

    ProductByOrderId<T extends ProductTransfer[]>(params: GetProductByOrderParams) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.productByOrderId,
                config: { params },
            });
            return req;
        });
    }

    ByDoc<T extends ByDocData[]>(params: GetProductParams) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.byDoc,
                config: { params },
            });
            return req;
        });
    }

    Delete<T extends any>(data: DeleteMM) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.delete,
                data,
            });
            return req;
        });
    }

    EditDraft<T extends any>(data: EditMM) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.editDraft,
                data,
            });
            return req;
        });
    }

    Edit<T extends any>(data: EditMM) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.put<T>({
                url: this.edit,
                data,
            });
            return req;
        });
    }

    Detail<T extends MaterialMovementDetail>(data: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.detail}/${data.id}`,
            });
            return req;
        });
    }

    CreateDraft<T extends any>(data: CreateMM) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.createDraft,
                data,
            });
            return req;
        });
    }

    Search<T extends MaterialMovement>(params: { page: any; query: any; type: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: { params },
            });
            return req;
        });
    }

    GetMaterialMovement<T extends MaterialMovement>(params: { page: any; type: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getMaterialMovement,
                config: { params },
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateMM) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    GetProduct<T extends ProductTransfer[]>(params: GetProductParams) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getProduct,
                config: { params },
            });
            return req;
        });
    }

    GetProduct2<T extends ProductTransfer[]>(params: GetProduct2Params) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getProduct,
                config: { params },
            });
            return req;
        });
    }

    GetLocatorFrom<T extends Locator[]>(params: { warehouse_id_from: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getLocatorFrom,
                config: { params },
            });
            return req;
        });
    }

    GetLocatorTo<T extends Locator[]>(params: { warehouse_id_to: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getLocatorTo,
                config: { params },
            });
            return req;
        });
    }
}

const materialMovementService = new MaterialMovementService();
export default materialMovementService;
