import Toolbar from "components/toolbar";
import useTypeSearchUrl from "hooks/useTypeSearchUrl";
import FilterCashierTransaksi from "modules/cashier-transaksi/filter";
import TableIndex from "modules/cashier-transaksi/table-index";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import cashierTransaksiService from "services/api-endpoints/cashier/cashier-transaksi";

const filter = ["page", "start_date", "end_date"];

const CashierTransaksi = () => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;

    const { isFilter, isSearch, searchValue, filterValue } = useTypeSearchUrl({
        search: ["query"],
        filter,
    });

    const getList = useQuery([cashierTransaksiService.getAll, page, searchValue, filterValue, isFilter, isSearch], async () => {
        if (isFilter) return (await cashierTransaksiService.Filter({ ...(filterValue as any) })).data.data;
        return (await cashierTransaksiService.GetAll()).data.data;
    });

    return (
        <div className="w-full flex flex-col gap-6">
            <FilterCashierTransaksi filter={filter}>
                {(dt) => (
                    <Toolbar placeholder="Search Invoice No" filterHandler={dt.openModal} onSearchHandler={dt.onClickClearHandler} add={false} />
                )}
            </FilterCashierTransaksi>
            <TableIndex fetcher={getList} />
        </div>
    );
};

export default CashierTransaksi;
