/* eslint-disable no-shadow */
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, Form, Space } from "antd";
import ToolbarAction from "components/toolbar/action";
import { Product } from "models";
import { BOMWithLocatorAndNote } from "modules/manufacturing-order/table";
import PrintPOM from "modules/purchasing-order-m/print-pom";
import TablePOMView from "modules/purchasing-order-m/table-pom-view";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AiFillPrinter } from "react-icons/ai";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import purchasingOrderMService, { CreatePOM } from "services/api-endpoints/dashboard/master-data/purchasing-order-m";
import * as yup from "yup";

const schema: yup.SchemaOf<Pick<CreatePOM, "notes">> = yup.object().shape({
    notes: yup.string().required("Note Required"),
});

export type ProductWithBOM = Product & {
    child?: BOMWithLocatorAndNote[];
    no?: number;
};

const PurchasingOrderMView = () => {
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const id = params.get("id");

    const [products, setProducts] = useState<ProductWithBOM[]>([]);

    const [form] = Form.useForm();
    const { handleSubmit, control } = useForm<CreatePOM>({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const letterRef = useRef<HTMLDivElement | null>(null);

    const detailQuery = useQuery(
        [purchasingOrderMService.detail],
        async () => {
            return (await purchasingOrderMService.Detail({ id })).data.data;
        },
        {
            onSuccess(data) {
                const parse = data.detail?.map((el, i) => ({
                    product_code1: el.note,
                    no: i,
                    child: el.child?.map((c) => {
                        return {
                            qty_bom: c.qty,
                            stock: c.stock,
                            code1: c.code1,
                        };
                    }) as BOMWithLocatorAndNote[],
                })) as ProductWithBOM[];
                setProducts(parse);
            },
        }
    );

    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `Purchasing Order`,
        pageStyle: "@page {  }",
    });

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <ToolbarAction
                title="detail purchasing order m"
                rightAddition={() => (
                    <Space>
                        <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                            <AiFillPrinter className="m-0 mr-2" />
                            Print
                        </Button>
                    </Space>
                )}
            />
            <Card>
                <Form className="col-span-2" form={form} layout="vertical">
                    <div className="w-full grid grid-cols-2 gap-x-4">
                        <p className="capitalize m-0">
                            Doc No <br />
                            <span className="m-0">{detailQuery.data?.po_no}</span>
                        </p>
                        <p className="capitalize m-0">
                            Notes <br />
                            <span className="m-0">{detailQuery.data?.note || "-"}</span>
                        </p>
                    </div>
                </Form>
            </Card>
            <TablePOMView list={products} setList={setProducts} action={false} />
            <PrintPOM ref={letterRef} data={{ ...detailQuery.data }} />
        </div>
    );
};

export default PurchasingOrderMView;
