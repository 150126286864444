/* eslint-disable no-useless-constructor */
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";
import * as Models from "models";
import { error } from "console";

export interface DocData {
    id: number;
    po_no: string;
    total_qty: number;
    total_product: string;
    created_at: any;
}

export interface ProductData {
    product_id?: any;
    product_name?: any;
    product_code1?: any;
    product_code2?: any;
    molding_code?: any;
    qty?: any;
}

export interface BomData {
    product_id?: any;
    code1?: any;
    qty_bom?: any;
    child?: BomData[];
}

export interface DetailProductData {
    product_id?: any;
    name?: any;
    code1?: any;
    molding_code?: any;
    bom?: BomData[];
}

export interface DetailData {
    error?: string;
    list_product: DetailProductData[];
}

export interface ProductChild {
    product_id: number;
    code1?: string;
    qty: number;
    stock: number;
}

export interface ProductDetail {
    note?: string;
    child?: ProductChild[];
}

export interface CreatePOM {
    id?: number;
    notes?: string;
    detail?: ProductDetail[];
}

export interface ListPOM {
    id?: number;
    po_no: string;
    note?: number;
    created_at?: number;
    created_by?: null | string;
}

export interface ListDetail {
    note?: string;
    child?: ProductChild[];
}

export interface POMDetailData {
    account_name?: string;
    account_address?: string;
    id?: number;
    po_no?: string;
    note?: string;
    detail?: ListDetail[];
}

class PhysicalInventoryService extends BaseService {
    listDoc = "/mf-po/get-document";

    getDetailProduct = "mf-po/detail-product-document";

    getProductDoc = "mf-po/get-product-document";

    create = "mf-po/create";

    list = "mf-po/get-list";

    detail = "mf-po/get-detail";

    constructor() {
        super();
    }

    GetDetailProduct<T extends DetailData>(param: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getDetailProduct,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Detail<T extends POMDetailData>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detail,
                config: { params },
            });
            return req;
        });
    }

    Create<T extends any>(data: CreatePOM) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }

    List<T extends ListPOM[]>(params: { doc_no?: any; start_date?: any; end_date?: any; page?: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.list,
                config: { params },
            });
            return req;
        });
    }

    GetProductDoc<T extends ProductData[]>(param: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getProductDoc,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    ListDoc<T extends DocData>(param: { page: any; query: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.listDoc,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const purchasingOrderMService = new PhysicalInventoryService();
export default purchasingOrderMService;
