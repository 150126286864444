import { Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import { useRef, useState } from "react";
import QRCode from "react-qr-code";
import { useQuery } from "react-query";
import workingOrderMService, { BarcodeData } from "services/api-endpoints/dashboard/manufacture/working-order-m";

type Props = ModalProps & {
    id: any;
    productId: any;
    productName: any;
};

const ModalBarcode = ({ id, productId, productName, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);
    const [page, setPage] = useState(1);

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPage(pagination.current || 1);
    };

    const barcodeWIPQuery = useQuery([workingOrderMService.barcodeWIP, productId], async () => {
        return (await workingOrderMService.BarcodeWIP({ id, product_id: productId })).data.data;
    });

    const columns: ColumnsType<BarcodeData> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => <p className="capitalize m-0">{(page - 1) * 20 + (i + 1)}</p>,
        },
        {
            title: "Unique Code",
            dataIndex: "qrcode",
        },
        {
            title: "QR Code",
            dataIndex: "qrcode",
            render: (text) => <QRCode size={60} value={text} />,
        },
    ];

    return (
        <ModalTemplate
            title={`Barcode Product (${productName})`}
            width={700}
            handlerInComponent={barcodeWIPQuery.isLoading ? () => <p>Getting Barcode data...</p> : children}
            footer={null}
            {...props}
        >
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <Table
                        rowKey={(record) => record.qrcode!}
                        size="small"
                        loading={barcodeWIPQuery?.isLoading}
                        columns={columns}
                        dataSource={barcodeWIPQuery?.data || []}
                        className="w-full"
                        pagination={{
                            pageSize: 20,
                            showSizeChanger: false,
                        }}
                        onChange={handleTableChange}
                    />
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalBarcode;
