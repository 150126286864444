/* eslint-disable no-useless-constructor */
import * as Models from "models";
import { BasePaginationResponseOrder, BasePaginationResponseRemarks, FilterDataOrder } from "modules/master-data/order/models";
import ApiMethod from "services/api-methods";
import BaseService from "services/api-endpoints/base";

export interface PaymentCustomChild {
    payment_amount?: number;
    payment_id?: number;
    payment_name?: string;
    cc_name?: string;
    cc_amount?: string;
}

export interface Order {
    address: string;
    alias_address: string;
    client_name: string;
    courier_name: string;
    created_at: string;
    discount: number;
    due_date: string;
    dp_no?: string;
    grand_total: number;
    id: number;
    invoice_no: string;
    invoice_reference: string;
    order_no?: string;
    order_status: number;
    paid_at: null | string;
    payment_channel: string;
    payment_status: number;
    shipping_cost: number;
    total_price: number;
    payment_custom?: PaymentCustomChild[];
    type: number;
    total_quantity: number;
    transfer_dest: string;
    is_approve: number;
    payment_receipt_url: null | string;
    discount_name: null | string;
    dp_amount?: number;
    sisa_pembayaran?: number;
    is_cancellation_req?: number;
    wo_id?: number;
    wo_no?: string;
    cc_amount?: number;
    cc_name?: string;
}

export interface AddRemark {
    remark: string;
    order_id: number;
}

export interface OrderProduct {
    order_detail_id: number;
    product_id: number;
    product_name: string;
    price: number;
    quantity: number;
    is_check: number;
    area_name: null | string;
    area_id: number | null;
    stock_quantity: number;
    is_service: number;
}

export interface Remark {
    id: number;
    remark: string;
    created_by: string;
    created_at: string;
}

export interface HistoryPayment {
    id: number;
    status: number | null;
    note1: null | string;
    note2: null | string;
    created_by: null | string;
    created_at: number;
}

export interface SetStatusPayment {
    order_id: number;
    status: number;
    note: string;
}

export interface SetStatusOrder {
    status: number;
    order_id: number;
}

export interface Locator {
    area_id: number;
    product_name: string;
    stock_qty: number;
    qty_threshold: number;
    area_name: string;
    is_active: number;
    is_consigment: number;
}

export interface DetailOrder {
    order_detail_id: number;
    area_id: number;
}
export interface IsCheckData {
    detail_order: DetailOrder[];
}

export interface History {
    order_history_id: string;
    note: null;
    created_by: null | string;
    created_at: number;
}

export interface StatusHistory {
    icon?: null;
    name?: string;
    order_status: number;
    list: History[];
}

export interface WrapImages {
    wrap_images: string[];
}

export interface QCDetailOrder {
    order_detail_id: number;
    qc_pass?: number | null;
}

export interface QCData {
    order_id: any;
    detail_order: QCDetailOrder[];
}

export interface OrderData {
    order_detail_id?: number;
    order_no?: string;
    product_name?: string;
    product_code?: string;
    product_owner?: string;
    qty?: number;
    date?: string;
    order_status?: string;
    marketplace_name?: string;
    client_name?: string;
    invoice_reference?: string;
    inv_no?: string;
}

export interface SummaryTrx {
    total_trx: number;
    marketplace_name: any;
}

export interface DownloadData {
    list: OrderData[];
    list_summary?: SummaryTrx[] | null;
}

export interface DownloadParams {
    type: number | null;
    start_date: any;
    end_date: any;
}

export interface VersioningHistory {
    order_history_id?: string;
    note?: string;
    created_by?: string;
    created_at?: number;
}

export interface Versioning {
    icon?: string;
    name?: string;
    order_status?: number;
    list?: VersioningHistory[];
}

export interface UploadReceiptData {
    id: any;
    receipt_image: any;
}

export interface TeknisiData {
    teknisi_id?: number;
    teknisi_name?: string;
    account_id?: number;
    from?: string;
}

export interface ProductWOData {
    product_id?: number;
    product_name?: string;
    product_code1?: string;
    product_code2?: string;
    product_price?: number;
    uom_id?: number;
    uom_name?: string;
    qty_use?: number;
    hpp?: number;
    total_hpp?: number;
}

export interface ProductAccountingData {
    product_id?: number;
    product_name?: string;
    product_code1?: string;
    price?: number;
    qty?: number;
    hpp?: number;
}

export interface DetailWOData {
    id?: number;
    wo_no?: string;
    teknisi?: TeknisiData[];
    cust_id?: number;
    cust_name?: string;
    address?: string;
    phone_number?: string;
    grand_hpp?: number;
    status?: number;
    list_product?: ProductWOData[];
    waste_product?: ProductWOData[];
}

class OrderService extends BaseService {
    getAll = "/master-order/list-order";

    search = "/master-order/search-order";

    filter = "/master-order/filter-order";

    detail = "/master-order/detail-order";

    addRemark = "/master-order/add-remark";

    detailOrderProduct = "/master-order/detail-order-product";

    getRemarks = "/master-order/get-remarks";

    paymentHistory = "/master-order/list-payment-history";

    setStatusPayment = "/master-order/set-status-payment";

    setStatusOrder = "/master-order/set-status-order";

    getLocator = "/master-order/get-locator";

    setIsCheck = "/master-order/set-is-check";

    getHistoryOrder = "/master-order/get-history-order";

    submitQC = "/master-order/submit-qc";

    allDetailProduct = "/master-order/all-detail-product";

    getImagesWrap = "/master-order/get-images-wrap";

    setQcCheck = "master-order/set-qc-check";

    downloadOrder = "master-order/download-data-order";

    getVersioning = "/master-order/get-versioning-history";

    rejectTransaction = "/master-order/reject-transaction";

    uploadReceipt = "/master-order/upload-receipt";

    listCancelOrder = "master-order/list-cancel-order";

    transactionCancel = "master-order/transaction-cancellation";

    detailWO = "master-order/detail-product-wo";

    detailWOAccounting = "master-order/product-wo-accounting";

    detailProductAccounting = "master-order/detail-product-accounting";

    resetCheck = "master-order/reset-check";

    constructor() {
        super();
    }

    ResetCheck<T extends any>(data: QCData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.resetCheck,
                data,
            });
            return req;
        });
    }

    DetailProductAccounting<T extends ProductAccountingData[]>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailProductAccounting,
                config: { params },
            });
            return req;
        });
    }

    DetailWOAccounting<T extends DetailWOData>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailWOAccounting,
                config: { params },
            });
            return req;
        });
    }

    DetailWO<T extends DetailWOData>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detailWO,
                config: { params },
            });
            return req;
        });
    }

    TransactionCancel<T extends any>(params: { id: any; type: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.transactionCancel,
                config: { params },
            });
            return req;
        });
    }

    ListCancelOrder<T extends Models.MasterDataOrder>(param: FilterDataOrder) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.listCancelOrder,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    UploadReceipt<T extends any>(data: UploadReceiptData, config?: Models.PostMethodParams["config"]) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.uploadReceipt,
                data,
                config,
            });
            return req;
        });
    }

    RejectTransaction<T extends any>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: `${this.rejectTransaction}/${id}`,
            });
            return req;
        });
    }

    GetVersioning<T extends Versioning[]>({ id }: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.getVersioning}/${id}`,
            });
            return req;
        });
    }

    DownloadOrder<T extends DownloadData>(params: DownloadParams) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.downloadOrder,
                config: { params },
            });
            return req;
        });
    }

    SetQcCheck<T extends any>(data: QCData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.setQcCheck,
                data,
            });
            return req;
        });
    }

    GetImagesWrap<T extends WrapImages>(params: { order_history_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getImagesWrap,
                config: { params },
            });
            return req;
        });
    }

    AllDetailProduct<T extends { list_order_product: OrderProduct[]; mode: any }>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.allDetailProduct,
                config: { params },
            });
            return req;
        });
    }

    SubmitQC<T extends any>(data: { order_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: `${this.submitQC}/${data.order_id}`,
            });
            return req;
        });
    }

    GetHistoryOrder<T extends StatusHistory[]>(data: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: `${this.getHistoryOrder}/${data.id}`,
            });
            return req;
        });
    }

    SetIsCheck<T extends any>(data: IsCheckData) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.setIsCheck,
                data,
            });
            return req;
        });
    }

    GetLocator<T extends Locator[]>(params: { product_id: number }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getLocator,
                config: { params },
            });
            return req;
        });
    }

    SetStatusOrder<T extends any>({ order_id: orderId, status }: SetStatusOrder) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: `${this.setStatusOrder}/${orderId}/${status}`,
            });
            return req;
        });
    }

    SetStatusPayment<T extends any>(data: SetStatusPayment) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.setStatusPayment,
                data,
            });
            return req;
        });
    }

    PaymentHistory<T extends HistoryPayment[]>(params: { order_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.paymentHistory,
                config: { params },
            });
            return req;
        });
    }

    GetRemarks<T extends Remark>(params: { id: any; page: any }) {
        return this.ProxyRequest<BasePaginationResponseRemarks<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponseRemarks<T>>({
                url: this.getRemarks,
                config: { params },
            });
            return req;
        });
    }

    DetailOrderProduct<T extends OrderProduct>(params: { id: any }) {
        return this.ProxyRequest<BasePaginationResponseOrder<T>>(async () => {
            const req = await ApiMethod.get<BasePaginationResponseOrder<T>>({
                url: this.detailOrderProduct,
                config: { params },
            });
            return req;
        });
    }

    AddRemark<T extends any>(data: AddRemark) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.addRemark,
                data,
            });
            return req;
        });
    }

    Detail<T extends Order>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detail,
                config: { params },
            });
            return req;
        });
    }

    GetAll<T extends Models.MasterDataOrder>(param: { page: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.getAll,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Search<T extends Models.MasterDataOrder>(param: { page: any; param_search: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.search,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    Filter<T extends Models.MasterDataOrder>(param: FilterDataOrder) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }
}

const orderService = new OrderService();
export default orderService;
