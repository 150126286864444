/* eslint-disable no-useless-constructor */
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";
import * as Models from "models";

export interface ProductManufacturingOrder {
    product_id?: number;
    product_name?: string;
    product_code1?: string;
    product_code2?: string;
    molding_code?: string;
    type?: number;
}

export interface ManufacturingOrderChild {
    product_id?: number;
    code?: string;
    qty?: number;
    area_id?: number;
    area_name?: string;
    note?: string;
}

export interface ManufacturingOrderDetail {
    product_id?: number;
    qty_set?: number;
    child?: ManufacturingOrderChild[];
}

export interface CreateManufacturingOrder {
    id?: number;
    resource_id?: number;
    notes?: string;
    detail?: ManufacturingOrderDetail[];
}

export interface BOM {
    product_id?: number;
    qty_bom?: number;
    code1?: string;
    qty_bom_temp?: number;
}

export interface MultipleChildBOM {
    child: {
        product_id: number;
        qty_bom: number;
        qty_stock?: number;
        code1: string;
        selected?: boolean;
        qty_bom_temp?: number;
    }[];
}

export interface ProductManufacturingOrderDetail {
    product_id?: number;
    name?: string;
    code1?: string;
    molding_code?: string;
    bom?: BOM[];
}

export interface ProductManufacturingOrderWithChildDetail extends Omit<ProductManufacturingOrderDetail, "bom"> {
    bom?: (BOM | MultipleChildBOM)[];
}

export interface LocatorManufacturingOrder {
    area_id?: number;
    area_name?: string;
    qty_locator?: string;
}

export interface ListManufacturingOrder {
    id?: number;
    order_no?: string;
    resource_id?: number;
    total_qty?: number;
    total_sku?: number;
    created_at?: number;
    created_by?: null | string;
}

export interface ListDetail {
    id?: number;
    manufacturing_order_id?: null;
    product_id?: number;
    qty_set?: number;
    code1?: string;
    molding_code?: string;
    name?: string;
    child_detail?: ManufacturingOrderChild[];
}

export interface ManufacturingOrderDetailData {
    id?: number;
    order_no?: string;
    account_name?: string;
    account_address?: string;
    resource_name?: string;
    resource_id?: number;
    total_sku?: number;
    total_qty?: number;
    notes?: string;
    detail?: ListDetail[];
}

class ManufacturingOrderService extends BaseService {
    create = "/manufacture/create-manufacturing-order";

    detail = "/manufacture/detail-manufacturing-order";

    getProduct = "/manufacture/get-product";

    getProductDetail = "/manufacture/get-product-detail";

    list = "/manufacture/list-manufacturing-order";

    locator = "/manufacture/get-locator";

    constructor() {
        super();
    }

    Locator<T extends LocatorManufacturingOrder[]>(params: { product_id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.locator,
                config: { params },
            });
            return req;
        });
    }

    List<T extends ListManufacturingOrder[]>(params: { doc_no?: any; start_date?: any; end_date?: any; page?: any }) {
        return this.ProxyRequest<Models.BasePaginationResponse<T>>(async () => {
            const req = await ApiMethod.get<Models.BasePaginationResponse<T>>({
                url: this.list,
                config: { params },
            });
            return req;
        });
    }

    GetProductDetail<T extends ProductManufacturingOrderDetail>(params: { product_id: number }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getProductDetail,
                config: { params },
            });
            return req;
        });
    }

    GetProduct<T extends ProductManufacturingOrder[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getProduct,
            });
            return req;
        });
    }

    Detail<T extends ManufacturingOrderDetailData>(params: { id: any }) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.detail,
                config: { params },
            });
            return req;
        });
    }

    Create<T extends any>(data: CreateManufacturingOrder) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.post<T>({
                url: this.create,
                data,
            });
            return req;
        });
    }
}

const manufacturingOrderService = new ManufacturingOrderService();
export default manufacturingOrderService;
