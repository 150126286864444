/* eslint-disable no-useless-constructor */
import BaseService from "services/api-endpoints/base";
import ApiMethod from "services/api-methods";

export interface CashierTransaksiList {
    id?: number;
    inv_no?: string;
    created_at?: string;
    dp?: string;
    order_status?: string;
}

export interface FilterCashier {
    start_date?: string;
    end_date?: string;
}

class CashierTransaksiService extends BaseService {
    getAll = "/giias/cashier/list-transaction";

    filter = "/giias/cashier/list-transaction/filter";

    // filter = "/giias/cashier/list-transaction/filter";

    constructor() {
        super();
    }

    // DetailCuti<T extends DetailCuti>({ id }: { id: any }) {
    //     return this.ProxyRequest<T>(async () => {
    //         const req = await ApiMethod.get<T>({
    //             url: `${this.detailCuti}/${id}`,
    //         });
    //         return req;
    //     });
    // }

    Filter<T extends CashierTransaksiList[]>(param: FilterCashier) {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.filter,
                config: {
                    params: {
                        ...param,
                    },
                },
            });
            return req;
        });
    }

    GetAll<T extends CashierTransaksiList[]>() {
        return this.ProxyRequest<T>(async () => {
            const req = await ApiMethod.get<T>({
                url: this.getAll,
            });
            return req;
        });
    }
}

const cashierTransaksiService = new CashierTransaksiService();
export default cashierTransaksiService;
