/* eslint-disable no-shadow */
import { Alert, Card, Descriptions, Skeleton } from "antd";
import StateRender from "components/common/state-render";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import hadirAppService from "services/api-endpoints/hadir-app/hadir-app-service";

const CashierTransaksiView = () => {
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id") as any;

    const detailQuery = useQuery(
        [hadirAppService.detailCuti, id],
        async () => {
            return (await hadirAppService.DetailCuti({ id })).data.data;
        },
        { enabled: !!id }
    );

    return (
        <div className="w-full flex flex-col gap-6">
            <StateRender data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                <StateRender.Data>
                    <Card>
                        <Descriptions title="Detail Cuti">
                            <Descriptions.Item label="Category Name">{detailQuery.data?.category_name}</Descriptions.Item>
                            <Descriptions.Item label="Description">{detailQuery.data?.description}</Descriptions.Item>
                            <Descriptions.Item label="Created At">{detailQuery.data?.created_at}</Descriptions.Item>
                            <Descriptions.Item label="User Name">{detailQuery.data?.user_name}</Descriptions.Item>
                            <Descriptions.Item label="Paid Date">{detailQuery.data?.paid_date?.map((date) => date).join(", ")}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                </StateRender.Data>
                <StateRender.Loading>
                    <Card>
                        <Skeleton />
                    </Card>
                </StateRender.Loading>
                <StateRender.Error>
                    <Alert message={(detailQuery.error as any)?.message} />
                </StateRender.Error>
            </StateRender>
        </div>
    );
};

export default CashierTransaksiView;
