/* eslint-disable no-restricted-globals */
import { MenuApps } from "models";
import Assembling from "pages/assembling";
import AssemblingAdd from "pages/assembling/add";
import Attribute from "pages/attribute";
import AttributeAdd from "pages/attribute/add";
import AtteibuteEdit from "pages/attribute/edit";
import BankAccount from "pages/bank-account";
import BankAccountAdd from "pages/bank-account/add";
import BankAccountEdit from "pages/bank-account/edit";
import Banner from "pages/banner";
import BannerAdd from "pages/banner/add";
import BannerEdit from "pages/banner/edit";
import Barcode from "pages/barcode";
import Bom from "pages/bom";
import BomAdd from "pages/bom/add";
import BomEdit from "pages/bom/edit";
import BPDeliveryOrder from "pages/bp-delivery-order";
import BPDeliveryOrderAdd from "pages/bp-delivery-order/add";
import BPDeliveryOrderView from "pages/bp-delivery-order/view";
import BPGoodReceipt from "pages/bp-goods-receipt";
import BPGoodsReceiptAdd from "pages/bp-goods-receipt/add";
import BPGoodsReceiptView from "pages/bp-goods-receipt/view";
import BPInvoice from "pages/bp-invoice";
import BPInvoiceView from "pages/bp-invoice/view";
import BpOrder from "pages/bp-order";
import BpOrderAdd from "pages/bp-order/add";
import BpOrderEdit from "pages/bp-order/edit";
import BpOrderView from "pages/bp-order/view";
import BPPickingList from "pages/bp-picking-list";
import BPPickingListAdd from "pages/bp-picking-list/add";
import BPPickingListEdit from "pages/bp-picking-list/edit";
import BPPickingListView from "pages/bp-picking-list/view";
import BPReturn from "pages/bp-return";
import BPReturnAdd from "pages/bp-return/add";
import BPReturnView from "pages/bp-return/view";
import BpSalesPage from "pages/bp-sales";
import BpSalesAdd from "pages/bp-sales/add";
import BpSalesEdit from "pages/bp-sales/edit";
import BpSalesView from "pages/bp-sales/view";
import Brand from "pages/brand";
import BrandAdd from "pages/brand/add";
import BrandEdit from "pages/brand/edit";
import BukuBesarAkun from "pages/buku-besar-per-akun";
import BukuPiutang from "pages/buku-piutang";
import BukuUtang from "pages/buku-utang";
import BusinessPartner from "pages/business-partner";
import BusinessPartnerAdd from "pages/business-partner/add";
import BusinessPartnerEdit from "pages/business-partner/edit";
import Buyer from "pages/buyer";
import BuyerView from "pages/buyer/view";
import CDeliveryOrder from "pages/c-delivery-order";
import CDeliveryOrderAdd from "pages/c-delivery-order/add";
import CDeliveryOrderView from "pages/c-delivery-order/view";
import CGenerateInvoice from "pages/c-generate-invoice";
import CGenerateInvoiceAdd from "pages/c-generate-invoice/add";
import CGenerateInvoiceView from "pages/c-generate-invoice/view";
import CInvoice from "pages/c-invoice";
import CInvoiceView from "pages/c-invoice/view";
import CPackingList from "pages/c-packing-list";
import CPackingListAdd from "pages/c-packing-list/add";
import CPackingListView from "pages/c-packing-list/view";
import CPickingDetail from "pages/c-picking-detail";
import CPickingDetailAdd from "pages/c-picking-detail/add";
import CPickingDetailEdit from "pages/c-picking-detail/edit";
import CPickingDetailView from "pages/c-picking-detail/view";
import CPurchaseOrder from "pages/c-purchase-order";
import CPurchaseOrderView from "pages/c-purchase-order/view";
import CReturnOrder from "pages/c-return-order";
import CReturnOrderAdd from "pages/c-return-order/add";
import CReturnOrderView from "pages/c-return-order/view";
import CSalesOrder from "pages/c-sales-order";
import CSalesOrderOutstanding from "pages/c-sales-order-outstanding";
import CSalesOrderEdit from "pages/c-sales-order/edit";
import CSalesOrderView from "pages/c-sales-order/view";
import Category from "pages/category";
import CategoryAdd from "pages/category/add";
import CategoryEdit from "pages/category/edit";
import Cuti from "pages/cuti";
import CutiView from "pages/cuti/view";
import DashboardAnalytic from "pages/dashboard-analytics";
import DashboardPOS from "pages/dashboard-pos";
import DashboardSales from "pages/dashboard-sales";
import DeliveryOrder from "pages/delivery-order";
import DeliveryOrderAdd from "pages/delivery-order/add";
import DeliveryOrderView from "pages/delivery-order/view";
import DocContainer from "pages/doc-container";
import DocContainerView from "pages/doc-container/view";
import DocInventory from "pages/doc-inventory";
import DocInventoryAdd from "pages/doc-inventory/add";
import DocInventoryView from "pages/doc-inventory/view";
import ExtDeliveryOrder from "pages/ext-delivery-order";
import ExtDeliveryOrderAdd from "pages/ext-delivery-order/add";
import ExtDeliveryOrderView from "pages/ext-delivery-order/view";
import ExtGoodsReceipt from "pages/ext-goods-receipt";
import ExtGoodsReceiptAdd from "pages/ext-goods-receipt/add";
import ExtGoodsReceiptView from "pages/ext-goods-receipt/view";
import ExtInvoice from "pages/ext-invoice";
import ExtInvoiceView from "pages/ext-invoice/view";
import ExtPackingList from "pages/ext-packing-list";
import ExtPackingListAdd from "pages/ext-packing-list/add";
import ExtPackingListView from "pages/ext-packing-list/view";
import ExtPickingDetail from "pages/ext-picking-detail";
import ExtPickingDetailAdd from "pages/ext-picking-detail/add";
import ExtPickingDetailEdit from "pages/ext-picking-detail/edit";
import ExtPickingDetailView from "pages/ext-picking-detail/view";
import ExtPurchaseOrder from "pages/ext-purchase-order";
import ExtPurchaseOrderView from "pages/ext-purchase-order/view";
import ExtReturnOrder from "pages/ext-return-order";
import ExtReturnOrderAdd from "pages/ext-return-order/add";
import ExtReturnOrderView from "pages/ext-return-order/view";
import ExtSalesOrder from "pages/ext-sales-order";
import ExtSalesOrderOutstanding from "pages/ext-sales-order-outstanding";
import ExtSalesOrderAdd from "pages/ext-sales-order/add";
import ExtSalesOrderEdit from "pages/ext-sales-order/edit";
import ExtSalesOrderView from "pages/ext-sales-order/view";
import FastMovingProduct from "pages/fast-moving-product";
import FastMovingProductView from "pages/fast-moving-product/view";
import GoodsInTransit from "pages/goods-in-transit";
import HistoryBank from "pages/history-bank";
import Holiday from "pages/holiday";
import HolidayAdd from "pages/holiday/add";
import HolidayEdit from "pages/holiday/edit";
import InventoryOut from "pages/inventory-out";
import InventoryOutAdd from "pages/inventory-out/add";
import InventoryOutEdit from "pages/inventory-out/edit";
import InventoryOutView from "pages/inventory-out/view";
import JurnalPembayaranKasBank from "pages/jurnal-pembayaran-kas-bank";
import JurnalPembelian from "pages/jurnal-pembelian";
import JurnalPenerimaanKasBank from "pages/jurnal-penerimaan-kas-bank";
import JurnalPenjualan from "pages/jurnal-penjualan";
import JurnalPenutup from "pages/jurnal-penutup";
import JurnalPenyesuaian from "pages/jurnal-penyesuaian";
import JurnalPenyesuaianAdd from "pages/jurnal-penyesuaian/add";
import JurnalPenyesuaianEdit from "pages/jurnal-penyesuaian/edit-draft";
import JurnalIndexUmum from "pages/jurnal-umum";
import JurnalUmumAdd from "pages/jurnal-umum/add";
import JurnalUmumEdit from "pages/jurnal-umum/edit";
import KartuStok from "pages/kartu-stok";
import KartuStokView from "pages/kartu-stok/view";
import Lembur from "pages/lembur";
import LemburView from "pages/lembur/view";
import ListCancelOrder from "pages/list-cancel-order";
import ListCancelOrderView from "pages/list-cancel-order/view";
import Locator from "pages/locator";
import Loyalty from "pages/loyalty";
import LoyaltyAdd from "pages/loyalty/add";
import LoyaltyView from "pages/loyalty/view";
import ManufactureBuilder from "pages/manufacture-builder";
import MasterBP from "pages/master-bp";
import MasterBPAdd from "pages/master-bp/add";
import MasterBPEdit from "pages/master-bp/edit";
import MasterCategoryCuti from "pages/master-category-cuti";
import MasterCategoryCutiAdd from "pages/master-category-cuti/add";
import MasterCategoryDutyTrip from "pages/master-category-duty-trip";
import MasterCategoryDutyTripAdd from "pages/master-category-duty-trip/add";
import MasterCoa from "pages/master-coa";
import MasterHoliday from "pages/master-holiday";
import MasterVariable from "pages/master-variable";
import MasterVariableAdd from "pages/master-variable/add";
import MasterVariableEdit from "pages/master-variable/edit";
import MaterialMovement from "pages/material-movement";
import MaterialMovementDoc from "pages/material-movement-doc";
import MaterialMovementDocAdd from "pages/material-movement-doc/add";
import MaterialMovementDocEdit from "pages/material-movement-doc/edit";
import MaterialMovementAdd from "pages/material-movement/add";
import MaterialMovementEdit from "pages/material-movement/edit";
import MaterialMovementView from "pages/material-movement/view";
import Menu from "pages/menu";
import MenuAdd from "pages/menu/add";
import MenuEdit from "pages/menu/edit";
import Model from "pages/model";
import ModelType from "pages/model-type";
import ModelTypeAdd from "pages/model-type/add";
import ModelTypeEdit from "pages/model-type/edit";
import ModelAdd from "pages/model/add";
import ModelEdit from "pages/model/edit";
import Order from "pages/order";
import OrderView from "pages/order/view";
import PackageProducts from "pages/package-products";
import PackageProductsAdd from "pages/package-products/add";
import PackageProductsEdit from "pages/package-products/edit";
import Payment from "pages/payment";
import PaymentAdd from "pages/payment/add";
import PaymentEdit from "pages/payment/edit";
import PaymentView from "pages/payment/view";
import PemakaianBarang from "pages/pemakaian-barang";
import PemakaianBarangAdd from "pages/pemakaian-barang/add";
import PemakaianBarangView from "pages/pemakaian-barang/view";
import PenerimaanBarang from "pages/penerimaan-barang";
import PenerimaanBarangAdd from "pages/penerimaan-barang/add";
import PenerimaanBarangView from "pages/penerimaan-barang/view";
import PerjalananDinas from "pages/perjalanan-dinas";
import PerjalananDinasView from "pages/perjalanan-dinas/view";
import PhysicalInventory from "pages/physical-inventory";
import PhysicalInventoryView from "pages/physical-inventory/view";
import PickingList from "pages/picking-list";
import PickingListAdd from "pages/picking-list/add";
import PickingListEdit from "pages/picking-list/edit";
import PickingListView from "pages/picking-list/view";
import Price from "pages/price";
import PriceAdd from "pages/price/add";
import PriceEdit from "pages/price/edit";
import Product from "pages/product";
import ProductAdd from "pages/product/add";
import ProductEdit from "pages/product/edit";
import PurchaseOrderExt from "pages/purchase-order-ext";
import PurchaseOrderAddExt from "pages/purchase-order-ext/add";
import PurchaseOrderEditExt from "pages/purchase-order-ext/edit";
import PurchaseOrderViewExt from "pages/purchase-order-ext/view";
import PurchaseOrder from "pages/purchase-order-int";
import PurchaseOrderAdd from "pages/purchase-order-int/add";
import PurchaseOrderEdit from "pages/purchase-order-int/edit";
import PurchaseOrderView from "pages/purchase-order-int/view";
import PurchasingInventory from "pages/purchasing-inventory";
import PurchasingInventoryAdd from "pages/purchasing-inventory/add";
import PurchasingInventoryEdit from "pages/purchasing-inventory/edit";
import PurchasingInventoryView from "pages/purchasing-inventory/view";
import PurchasingRequest from "pages/purchasing-request";
import PurchasingRequestAdd from "pages/purchasing-request/add";
import PurchasingRequestEdit from "pages/purchasing-request/edit";
import PurchasingRequestView from "pages/purchasing-request/view";
import Quantity from "pages/quantity";
import QuantityView from "pages/quantity/view";
import RawMaterial from "pages/raw-material";
import RawMaterialAdd from "pages/raw-material/add";
import RawMaterialEdit from "pages/raw-material/edit";
import Receipt from "pages/receipt";
import ReceiptAdd from "pages/receipt/add";
import ReceiptEdit from "pages/receipt/edit";
import ReceiptView from "pages/receipt/view";
import RekapAutoloka from "pages/rekap-autoloka";
import RekapBpSales from "pages/rekap-bp-sales";
import RekapOffline from "pages/rekap-offline";
import RekapOfflineAccounting from "pages/rekap-offline-accounting";
import RekapOnline from "pages/rekap-online";
import RekapOnlineAccounting from "pages/rekap-online-accounting";
import ReportNeracaSaldo from "pages/report-neraca-saldo";
import RequestIntPO from "pages/request-int-po";
import RequestIntPOView from "pages/request-int-po/view";
import ReturnOrder from "pages/return-order";
import ReturnOrderAdd from "pages/return-order/add";
import ReturnOrderView from "pages/return-order/view";
import RoleMenu from "pages/role-menu";
import RoleMenuAdd from "pages/role-menu/add";
import RoleMenuEdit from "pages/role-menu/edit";
import RoutineStock from "pages/routine-stock";
import RoutineStockView from "pages/routine-stock/view";
import Sales from "pages/sales";
import SalesOrder from "pages/sales-order";
import SalesOrderAdd from "pages/sales-order/add";
import SalesOrderEdit from "pages/sales-order/edit";
import SalesOrderView from "pages/sales-order/view";
import SalesPerformance from "pages/sales-performance";
import SalesPerformanceView from "pages/sales-performance/view";
import SalesProduct from "pages/sales-product";
import SalesAdd from "pages/sales/add";
import SalesEdit from "pages/sales/edit";
import SalesView from "pages/sales/view";
import SeasonalProduct from "pages/seasonal-product";
import SeasonalProductAdd from "pages/seasonal-product/add";
import SeasonalProductEdit from "pages/seasonal-product/edit";
import StockLocator from "pages/stock-locator";
import StockLocatorView from "pages/stock-locator/view";
import StockProduct from "pages/stock-product";
import StockProductView from "pages/stock-product/view";
import Style from "pages/style";
import StyleAdd from "pages/style/add";
import StyleEdit from "pages/style/edit";
import SubAttribute from "pages/sub-attribute";
import SubAttributeAdd from "pages/sub-attribute/add";
import SubAttributEdit from "pages/sub-attribute/edit";
import SubCategory from "pages/sub-category";
import SubcategoryAdd from "pages/sub-category/add";
import SubCategoryEdit from "pages/sub-category/edit";
import Supplies from "pages/supplies";
import SuppliesQuantity from "pages/supplies-quantity";
import SuppliesQuantityView from "pages/supplies-quantity/view";
import SuppliesType from "pages/supplies-type";
import SuppliesTypeAdd from "pages/supplies-type/add";
import SuppliesTypeEdit from "pages/supplies-type/edit";
import SuppliesAdd from "pages/supplies/add";
import SuppliesEdit from "pages/supplies/edit";
import TeamForceTask from "pages/team-force-task";
import TeamForceTaskView from "pages/team-force-task/view";
import TechnicianPerformance from "pages/technician-performance";
import TechnicianPerformanceView from "pages/technician-performance/view";
import TestPage from "pages/test";
import Transaction from "pages/transaction";
import TransferBank from "pages/transfer-bank";
import TransferBankCabang from "pages/transfer-bank-cabang";
import TransferBankCabangAdd from "pages/transfer-bank-cabang/add";
import TransferBankCabangEdit from "pages/transfer-bank-cabang/edit";
import TransferBankCabangView from "pages/transfer-bank-cabang/view";
import TransferBankAdd from "pages/transfer-bank/add";
import TransferBankEdit from "pages/transfer-bank/edit";
import TransferBankView from "pages/transfer-bank/view";
import TransferStock from "pages/transfer-stock";
import TransferStockAdd from "pages/transfer-stock/add";
import TransferStockEdit from "pages/transfer-stock/edit";
import TransferStockView from "pages/transfer-stock/view";
import User from "pages/user";
import UserApps from "pages/user-apps";
import Role from "pages/user-role";
import UserRoleMenu from "pages/user-role-menu";
import UserRoleMenuAdd from "pages/user-role-menu/add";
import UserRoleMenuEdit from "pages/user-role-menu/edit";
import UserView from "pages/user/view";
import Voucher from "pages/voucher";
import VoucherAdd from "pages/voucher/add";
import VoucherView from "pages/voucher/view";
import WorkingOrder from "pages/working-order";
import WorkingOrderView from "pages/working-order/view";
import { ADD_PATH, EDIT_PATH, VIEW_PATH } from "./constant";
import ManufacturingOrder from "pages/manufacturing-order";
import ManufacturingOrderAdd from "pages/manufacturing-order/add";
import ManufacturingOrderEdit from "pages/manufacturing-order/edit";
import PurchasingOrderM from "pages/purchasing-order-m";
import PurchasingOrderMAdd from "pages/purchasing-order-m/add";
import PurchasingOrderMView from "pages/purchasing-order-m/view";
import WorkingOrderM from "pages/working-order-m";
import WorkingOrderMAdd from "pages/working-order-m/add";
import WorkingOrderMView from "pages/working-order-m/view";
import WorkingOrderMEdit from "pages/working-order-m/edit";
import GrInventory from "pages/gr-inventory";
import GrInventoryView from "pages/gr-inventory/view";
import CashierTransaksi from "pages/cashier-transaksi";
import CashierTransaksiView from "pages/cashier-transaksi/view";

export const dashboardMenu: MenuApps["menus"][0] = {
    name: "dashboard",
    menu: {
        analytics: {
            index: {
                route: "/dashboard/analytics",
                page: DashboardAnalytic,
            },
        },
        sales: {
            index: {
                route: "/dashboard/sales",
                page: DashboardSales,
            },
        },
    },
};

export const reportMenu: MenuApps["menus"][0] = {
    name: "report",
    menu: {
        sales: {
            index: {
                route: "/report/sales",
                page: SalesProduct,
            },
        },
        transactions: {
            index: {
                route: "/report/transaction",
                page: Transaction,
            },
        },
    },
};

export const masterDataMenu: MenuApps["menus"][0] = {
    name: "master-data",
    menu: {
        brand: {
            index: {
                route: "/master-data/brand",
                page: Brand,
            },
            add: {
                route: `/master-data/brand${ADD_PATH}`,
                page: BrandAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/master-data/brand${EDIT_PATH}`,
                page: BrandEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        model: {
            index: {
                route: "/master-data/model",
                page: Model,
            },
            add: {
                route: `/master-data/model${ADD_PATH}`,
                page: ModelAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/master-data/model${EDIT_PATH}`,
                page: ModelEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "model-type": {
            index: {
                route: "/master-data/model-type",
                page: ModelType,
            },
            add: {
                route: `/master-data/model-type${ADD_PATH}`,
                page: ModelTypeAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/master-data/model-type${EDIT_PATH}`,
                page: ModelTypeEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        category: {
            index: {
                route: "/master-data/category",
                page: Category,
            },
            add: {
                route: `/master-data/category${ADD_PATH}`,
                page: CategoryAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/master-data/category${EDIT_PATH}`,
                page: CategoryEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        subcategory: {
            index: {
                route: "/master-data/subcategory",
                page: SubCategory,
            },
            add: {
                route: `/master-data/subcategory${ADD_PATH}`,
                page: SubcategoryAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/master-data/subcategory${EDIT_PATH}`,
                page: SubCategoryEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        style: {
            index: {
                route: "/master-data/style",
                page: Style,
            },
            add: {
                route: `/master-data/style${ADD_PATH}`,
                page: StyleAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/master-data/style${EDIT_PATH}`,
                page: StyleEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        attribute: {
            index: {
                route: "/master-data/attribute",
                page: Attribute,
            },
            add: {
                route: `/master-data/attribute${ADD_PATH}`,
                page: AttributeAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/master-data/attribute${EDIT_PATH}`,
                page: AtteibuteEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        subattribute: {
            index: {
                route: "/master-data/subattribute",
                page: SubAttribute,
                backHandlerOverride() {
                    history.back();
                },
            },
            add: {
                route: `/master-data/subattribute${ADD_PATH}`,
                page: SubAttributeAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/master-data/subattribute${EDIT_PATH}`,
                page: SubAttributEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "business-partner": {
            index: {
                route: "/master-data/business-partner",
                page: BusinessPartner,
            },
            add: {
                route: `/master-data/business-partner${ADD_PATH}`,
                page: BusinessPartnerAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/master-data/business-partner${EDIT_PATH}`,
                page: BusinessPartnerEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        locator: {
            index: {
                route: "/master-data/locator",
                page: Locator,
            },
        },
        product: {
            "item-products": {
                index: {
                    route: "/master-data/product/item-products",
                    page: Product,
                },
                add: {
                    route: `/master-data/product/item-products${ADD_PATH}`,
                    page: ProductAdd,
                    backHandlerOverride() {
                        history.back();
                    },
                },
                edit: {
                    route: `/master-data/product/item-products${EDIT_PATH}`,
                    page: ProductEdit,
                    backHandlerOverride() {
                        history.back();
                    },
                },
            },
            "package-products": {
                index: {
                    route: "/master-data/product/package-products",
                    page: PackageProducts,
                },
                add: {
                    route: `/master-data/product/package-products${ADD_PATH}`,
                    page: PackageProductsAdd,
                    backHandlerOverride() {
                        history.back();
                    },
                },
                edit: {
                    route: `/master-data/product/package-products${EDIT_PATH}`,
                    page: PackageProductsEdit,
                    backHandlerOverride() {
                        history.back();
                    },
                },
            },
        },
        supplies: {
            "item-supplies": {
                index: {
                    route: "/master-data/supplies/item-supplies",
                    page: Supplies,
                },
                add: {
                    route: `/master-data/supplies/item-supplies${ADD_PATH}`,
                    page: SuppliesAdd,
                    backHandlerOverride() {
                        history.back();
                    },
                },
                edit: {
                    route: `/master-data/supplies/item-supplies${EDIT_PATH}`,
                    page: SuppliesEdit,
                    backHandlerOverride() {
                        history.back();
                    },
                },
            },
            "type-of-supplies": {
                index: {
                    route: "/master-data/supplies/type-of-supplies",
                    page: SuppliesType,
                },
                add: {
                    route: `/master-data/supplies/type-of-supplies${ADD_PATH}`,
                    page: SuppliesTypeAdd,
                    backHandlerOverride() {
                        history.back();
                    },
                },
                edit: {
                    route: `/master-data/supplies/type-of-supplies${EDIT_PATH}`,
                    page: SuppliesTypeEdit,
                    backHandlerOverride() {
                        history.back();
                    },
                },
            },
            "hari-libur": {
                index: {
                    route: "/master-data/hari-libur",
                    page: Holiday,
                },
                add: {
                    route: `/master-data/hari-libur${ADD_PATH}`,
                    page: HolidayAdd,
                    backHandlerOverride() {
                        history.back();
                    },
                },
                edit: {
                    route: `/master-data/hari-libur${EDIT_PATH}`,
                    page: HolidayEdit,
                    backHandlerOverride() {
                        history.back();
                    },
                },
            },
            "kategory-perjalanan-dinas": {
                index: {
                    route: "/master-data/kategori-perjalanan-dinas",
                    page: MasterCategoryDutyTrip,
                },
                add: {
                    route: `/master-data/kategori-perjalanan-dinas${ADD_PATH}`,
                    page: MasterCategoryDutyTripAdd,
                },
            },
            "kategory-cuti": {
                index: {
                    route: "/master-data/kategori-cuti",
                    page: MasterCategoryCuti,
                },
                add: {
                    route: `/master-data/kategori-cuti${ADD_PATH}`,
                    page: MasterCategoryCutiAdd,
                    backHandlerOverride() {
                        history.back();
                    },
                },
            },
        },
    },
};

export const productMenu: MenuApps["menus"][0] = {
    name: "product",
    menu: {
        "item-products": {
            index: {
                route: "/product/item-products",
                page: Product,
            },
            add: {
                route: `/product/item-products${ADD_PATH}`,
                page: ProductAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/product/item-products${EDIT_PATH}`,
                page: ProductEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "package-products": {
            index: {
                route: "/product/package-products",
                page: PackageProducts,
            },
            add: {
                route: `/product/package-products${ADD_PATH}`,
                page: PackageProductsAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/product/package-products${EDIT_PATH}`,
                page: PackageProductsEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const warehouseMenu: MenuApps["menus"][0] = {
    name: "warehouse",
    menu: {
        "product-price": {
            index: {
                route: "/warehouse/product-price",
                page: Price,
            },
            add: {
                route: `/warehouse/product-price${ADD_PATH}`,
                page: PriceAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/warehouse/product-price${EDIT_PATH}`,
                page: PriceEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "product-quantity": {
            index: {
                route: "/warehouse/product-quantity",
                page: Quantity,
            },
            view: {
                route: `/warehouse/product-quantity${VIEW_PATH}`,
                page: QuantityView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "supplies-quantity": {
            index: {
                route: "/warehouse/supplies-quantity",
                page: SuppliesQuantity,
            },
            view: {
                route: `/warehouse/supplies-quantity${VIEW_PATH}`,
                page: SuppliesQuantityView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "doc-inventory": {
            index: {
                route: "/warehouse/doc-inventory",
                page: DocInventory,
            },
            add: {
                route: `/warehouse/doc-inventory${ADD_PATH}`,
                page: DocInventoryAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/warehouse/doc-inventory${VIEW_PATH}`,
                page: DocInventoryView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "routine-stock": {
            index: {
                route: "/warehouse/routine-stock",
                page: RoutineStock,
            },
            view: {
                route: `/warehouse/routine-stock${VIEW_PATH}`,
                page: RoutineStockView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "material-movement": {
            index: {
                route: "/warehouse/material-movement",
                page: MaterialMovement,
            },
            add: {
                route: `/warehouse/material-movement${ADD_PATH}`,
                page: MaterialMovementAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/warehouse/material-movement${EDIT_PATH}`,
                page: MaterialMovementEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/warehouse/material-movement${VIEW_PATH}`,
                page: MaterialMovementView,
            },
        },
        "material-movement-doc": {
            index: {
                route: "/warehouse/material-movement-doc",
                page: MaterialMovementDoc,
            },
            add: {
                route: `/warehouse/material-movement-doc${ADD_PATH}`,
                page: MaterialMovementDocAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/warehouse/material-movement-doc${EDIT_PATH}`,
                page: MaterialMovementDocEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "transfer-stock": {
            index: {
                route: "/warehouse/transfer-stock",
                page: TransferStock,
            },
            add: {
                route: `/warehouse/transfer-stock${ADD_PATH}`,
                page: TransferStockAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/warehouse/transfer-stock${VIEW_PATH}`,
                page: TransferStockView,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/warehouse/transfer-stock${EDIT_PATH}`,
                page: TransferStockEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "physical-inventory": {
            index: {
                route: "/warehouse/physical-inventory",
                page: PhysicalInventory,
            },
            view: {
                route: `/warehouse/physical-inventory${VIEW_PATH}`,
                page: PhysicalInventoryView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "stock-product": {
            index: {
                route: "/warehouse/stock-product",
                page: StockProduct,
            },
            view: {
                route: `/warehouse/stock-product${VIEW_PATH}`,
                page: StockProductView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "stock-locator": {
            index: {
                route: "/warehouse/stock-locator",
                page: StockLocator,
            },
            view: {
                route: `/warehouse/stock-locator${VIEW_PATH}`,
                page: StockLocatorView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "doc-container": {
            index: {
                route: "/warehouse/do-container",
                page: DocContainer,
            },
            view: {
                route: `/warehouse/do-container${VIEW_PATH}`,
                page: DocContainerView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const documentMenu: MenuApps["menus"][0] = {
    name: "document",
    menu: {
        "request-int-purchase-order": {
            index: {
                route: "/document/request-int-purchase-order",
                page: RequestIntPO,
            },
            view: {
                route: `/document/request-int-purchase-order${VIEW_PATH}`,
                page: RequestIntPOView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "int-purchase-order": {
            index: {
                route: "/document/int-purchase-order",
                page: PurchaseOrder,
            },
            add: {
                route: `/document/int-purchase-order${ADD_PATH}`,
                page: PurchaseOrderAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/document/int-purchase-order${EDIT_PATH}`,
                page: PurchaseOrderEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/document/int-purchase-order${VIEW_PATH}`,
                page: PurchaseOrderView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "ext-purchase-order": {
            index: {
                route: "/document/ext-purchase-order",
                page: PurchaseOrderExt,
            },
            add: {
                route: `/document/ext-purchase-order${ADD_PATH}`,
                page: PurchaseOrderAddExt,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/document/ext-purchase-order${EDIT_PATH}`,
                page: PurchaseOrderEditExt,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/document/ext-purchase-order${VIEW_PATH}`,
                page: PurchaseOrderViewExt,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "sales-order": {
            index: {
                route: "/document/sales-order",
                page: SalesOrder,
            },
            add: {
                route: `/document/sales-order${ADD_PATH}`,
                page: SalesOrderAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/document/sales-order${VIEW_PATH}`,
                page: SalesOrderView,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/document/sales-order${EDIT_PATH}`,
                page: SalesOrderEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "picking-list": {
            index: {
                route: "/document/picking-list",
                page: PickingList,
            },
            add: {
                route: `/document/picking-list${ADD_PATH}`,
                page: PickingListAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/document/picking-list${VIEW_PATH}`,
                page: PickingListView,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/document/picking-list${EDIT_PATH}`,
                page: PickingListEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "delivery-order": {
            index: {
                route: "/document/delivery-order",
                page: DeliveryOrder,
            },
            add: {
                route: `/document/delivery-order${ADD_PATH}`,
                page: DeliveryOrderAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/document/delivery-order${VIEW_PATH}`,
                page: DeliveryOrderView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "inventory-out": {
            index: {
                route: "/document/inventory-out",
                page: InventoryOut,
            },
            add: {
                route: `/document/inventory-out${ADD_PATH}`,
                page: InventoryOutAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/document/inventory-out${VIEW_PATH}`,
                page: InventoryOutView,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/document/inventory-out${EDIT_PATH}`,
                page: InventoryOutEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "return-order": {
            index: {
                route: "/document/return-order",
                page: ReturnOrder,
            },
            add: {
                route: `/document/return-order${ADD_PATH}`,
                page: ReturnOrderAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/document/return-order${VIEW_PATH}`,
                page: ReturnOrderView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const promoMenu: MenuApps["menus"][0] = {
    name: "promo",
    menu: {
        voucher: {
            index: { route: "/promo/voucher", page: Voucher },
            view: { route: `/promo/voucher${VIEW_PATH}`, page: VoucherView },
            add: { route: `/promo/voucher${ADD_PATH}`, page: VoucherAdd },
        },
        loyalty: {
            index: { route: "/promo/loyalty", page: Loyalty },
            view: { route: `/promo/loyalty${VIEW_PATH}`, page: LoyaltyView },
            add: { route: `/promo/loyalty${ADD_PATH}`, page: LoyaltyAdd },
        },
    },
};

export const featureMenu: MenuApps["menus"][0] = {
    name: "feature",
    menu: {
        banner: {
            index: { route: "/feature/banner", page: Banner },
            add: { route: `/feature/banner${ADD_PATH}`, page: BannerAdd },
            edit: { route: `/feature/banner${EDIT_PATH}`, page: BannerEdit },
        },
        "seasonal-product": {
            index: {
                route: "/feature/seasonal-product",
                page: SeasonalProduct,
            },
            add: {
                route: `/feature/seasonal-product${ADD_PATH}`,
                page: SeasonalProductAdd,
            },
            edit: {
                route: `feature/seasonal-product${EDIT_PATH}`,
                page: SeasonalProductEdit,
            },
        },
    },
};

export const userManagementMenu: MenuApps["menus"][0] = {
    name: "user-management",
    menu: {
        user: {
            index: {
                route: "/user-management/user",
                page: User,
            },
            view: {
                route: `/user-management/user${VIEW_PATH}`,
                page: UserView,
            },
        },
        buyer: {
            index: {
                route: "/user-management/buyer",
                page: Buyer,
            },
            view: {
                route: `/user-management/buyer${VIEW_PATH}`,
                page: BuyerView,
            },
        },
        role: {
            index: {
                route: "/user-management/role",
                page: Role,
            },
        },
        menu: {
            index: {
                route: "/user-management/menu",
                page: Menu,
            },
            add: {
                route: `/user-management/menu${ADD_PATH}`,
                page: MenuAdd,
            },
            edit: {
                route: `/user-management/menu${EDIT_PATH}`,
                page: MenuEdit,
            },
        },
        "role-menu": {
            index: {
                route: "/user-management/role-menu",
                page: RoleMenu,
            },
            add: {
                route: `/user-management/role-menu${ADD_PATH}`,
                page: RoleMenuAdd,
            },
            edit: {
                route: `/user-management/role-menu${EDIT_PATH}`,
                page: RoleMenuEdit,
            },
        },
        apps: {
            index: {
                route: "/user-management/apps",
                page: UserApps,
            },
        },
        "user-role-menu": {
            index: {
                route: "/user-management/user-role-menu",
                page: UserRoleMenu,
            },
            add: {
                route: `/user-management/user-role-menu${ADD_PATH}`,
                page: UserRoleMenuAdd,
            },
            edit: {
                route: `/user-management/user-role-menu${EDIT_PATH}`,
                page: UserRoleMenuEdit,
            },
        },
    },
};

export const printMenu: MenuApps["menus"][0] = {
    name: "print",
    menu: {
        barcode: {
            index: {
                route: "/print/barcode",
                page: Barcode,
            },
        },
    },
};

export const dashboardPosMenu: MenuApps["menus"][0] = {
    name: "dashboard",
    menu: {
        dashboard: {
            index: {
                route: "/dashboard",
                page: DashboardPOS,
            },
        },
        "sales-performance": {
            index: {
                route: "/sales-performance",
                page: SalesPerformance,
            },
            view: {
                route: `/sales-performance${VIEW_PATH}`,
                page: SalesPerformanceView,
            },
        },
        "teknisi-performance": {
            index: {
                route: "/teknisi-performance",
                page: TechnicianPerformance,
            },
            view: {
                route: `/teknisi-performance${VIEW_PATH}`,
                page: TechnicianPerformanceView,
            },
        },
    },
};

export const salesPosMenu: MenuApps["menus"][0] = {
    name: "sales",
    menu: {
        sales: {
            index: {
                route: "/sales",
                page: Sales,
            },
            add: {
                route: `/sales${ADD_PATH}`,
                page: SalesAdd,
            },
            view: {
                route: `/sales${VIEW_PATH}`,
                page: SalesView,
            },
            edit: {
                route: `/sales${EDIT_PATH}`,
                page: SalesEdit,
            },
        },
    },
};

export const bpSalesPosMenu: MenuApps["menus"][0] = {
    name: "bp-sales",
    menu: {
        "bp-sales": {
            index: {
                route: "/bp-sales",
                page: BpSalesPage,
            },
            add: {
                route: `/bp-sales${ADD_PATH}`,
                page: BpSalesAdd,
            },
            view: {
                route: `/bp-sales${VIEW_PATH}`,
                page: BpSalesView,
            },
            edit: {
                route: `/bp-sales${EDIT_PATH}`,
                page: BpSalesEdit,
            },
        },
    },
};

export const salesMenu: MenuApps["menus"][0] = {
    name: "sales",
    menu: {
        "bp-order": {
            index: {
                route: "/sales/bp-order",
                page: BpOrder,
            },
            add: {
                route: `/sales/bp-order${ADD_PATH}`,
                page: BpOrderAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/sales/bp-order${EDIT_PATH}`,
                page: BpOrderEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/sales/bp-order${VIEW_PATH}`,
                page: BpOrderView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "bp-picking-list": {
            index: {
                route: "/sales/bp-picking-list",
                page: BPPickingList,
            },
            add: {
                route: `/sales/bp-picking-list${ADD_PATH}`,
                page: BPPickingListAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/sales/bp-picking-list${EDIT_PATH}`,
                page: BPPickingListEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/sales/bp-picking-list${VIEW_PATH}`,
                page: BPPickingListView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "bp-delivery-order": {
            index: {
                route: "/sales/bp-delivery-order",
                page: BPDeliveryOrder,
            },
            add: {
                route: `/sales/bp-delivery-order${ADD_PATH}`,
                page: BPDeliveryOrderAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/sales/bp-delivery-order${VIEW_PATH}`,
                page: BPDeliveryOrderView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "bp-goods-receipt": {
            index: {
                route: "/sales/bp-goods-receipt",
                page: BPGoodReceipt,
            },
            add: {
                route: `/sales/bp-goods-receipt${ADD_PATH}`,
                page: BPGoodsReceiptAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/sales/bp-goods-receipt${VIEW_PATH}`,
                page: BPGoodsReceiptView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "bp-invoice": {
            index: {
                route: "/sales/bp-invoice",
                page: BPInvoice,
            },
            view: {
                route: `/sales/bp-invoice${VIEW_PATH}`,
                page: BPInvoiceView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "bp-return": {
            index: {
                route: "/sales/bp-return",
                page: BPReturn,
            },
            add: {
                route: `/sales/bp-return${ADD_PATH}`,
                page: BPReturnAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/sales/bp-return${VIEW_PATH}`,
                page: BPReturnView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const masterAccountingMenu: MenuApps["menus"][0] = {
    name: "master",
    menu: {
        "master-coa": {
            index: {
                route: "/master/master-coa",
                page: MasterCoa,
            },
            // TAMBAH DAN EDIT DIHALAMAN INDEX
            // add: {
            //     route: `/master/master-coa${ADD_PATH}`,
            //     page: MasterCoaAdd,
            // },
            // edit: {
            //     route: `/master/master-coa${EDIT_PATH}`,
            //     page: MasterCoaEdit, // TODO not complete
            // },
        },
        "business-partner": {
            index: {
                route: "/master/business-partner",
                page: MasterBP,
            },
            add: {
                route: `/master/business-partner${ADD_PATH}`,
                page: MasterBPAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/master/business-partner${EDIT_PATH}`,
                page: MasterBPEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "bank-account": {
            index: {
                route: "/master/bank-account",
                page: BankAccount,
            },
            add: {
                route: `/master/bank-account${ADD_PATH}`,
                page: BankAccountAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/master/bank-account${EDIT_PATH}`,
                page: BankAccountEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const pdfConverterMenu: MenuApps["menus"][0] = {
    name: "pdf-converter",
    menu: {
        "pdf-converter": {
            index: {
                route: "/other/pdf-converter",
                page: TestPage, // TODO
            },
        },
    },
};

export const companyAssetMenu: MenuApps["menus"][0] = {
    name: "company-asset",
    menu: {
        inventory: {
            index: {
                route: "/company-asset/inventory",
                page: TestPage, // TODO
            },
        },
    },
};

export const paymentMenu: MenuApps["menus"][0] = {
    name: "payment",
    menu: {
        payment: {
            index: {
                route: "/payment",
                page: Payment,
            },
            add: {
                route: `/payment${ADD_PATH}`,
                page: PaymentAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/payment${VIEW_PATH}`,
                page: PaymentView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const jurnalMenu: MenuApps["menus"][0] = {
    name: "jurnal",
    menu: {
        jurnal: {
            "jurnal-penjualan": {
                index: {
                    route: "/jurnal/jurnal-penjualan",
                    page: JurnalPenjualan,
                },
            },
            "jurnal-pembelian": {
                index: {
                    route: "/jurnal/jurnal-pembelian",
                    page: JurnalPembelian,
                },
            },
            "jurnal-penerimaan-kas-bank": {
                index: {
                    route: "/jurnal/jurnal-penerimaan-kas-bank",
                    page: JurnalPenerimaanKasBank,
                },
            },
            "jurnal-pembayaran-kas-bank": {
                index: {
                    route: "/jurnal/jurnal-pembayaran-kas-bank",
                    page: JurnalPembayaranKasBank,
                },
            },
            "jurnal-penutup": {
                index: {
                    route: "/jurnal/jurnal-penutup",
                    page: JurnalPenutup,
                },
            },
            "jurnal-penyesuaian": {
                index: {
                    route: "/jurnal/jurnal-penyesuaian",
                    page: JurnalPenyesuaian,
                },
                add: {
                    route: `/jurnal/jurnal-penyesuaian${ADD_PATH}`,
                    page: JurnalPenyesuaianAdd,
                    backHandlerOverride() {
                        history.back();
                    },
                },
                edit: {
                    route: `/jurnal/jurnal-penyesuaian${EDIT_PATH}`,
                    page: JurnalPenyesuaianEdit,
                    backHandlerOverride() {
                        history.back();
                    },
                },
            },
            "jurnal-umum": {
                index: {
                    route: "/jurnal/jurnal-umum",
                    page: JurnalIndexUmum,
                },
                add: {
                    route: `/jurnal/jurnal-umum${ADD_PATH}`,
                    page: JurnalUmumAdd,
                    backHandlerOverride() {
                        history.back();
                    },
                },
                edit: {
                    route: `/jurnal/jurnal-umum${EDIT_PATH}`,
                    page: JurnalUmumEdit,
                    backHandlerOverride() {
                        history.back();
                    },
                },
            },
        },
    },
};

export const persendianMenu: MenuApps["menus"][0] = {
    name: "persediaan",
    menu: {
        persediaan: {
            "kartu-stok": {
                index: {
                    route: "/persediaan/kartu-stok",
                    page: KartuStok,
                },
                view: {
                    route: `/persediaan/kartu-stok${VIEW_PATH}`,
                    page: KartuStokView,
                    backHandlerOverride() {
                        history.back();
                    },
                },
            },
        },
    },
};

export const bukuBesarMenu: MenuApps["menus"][0] = {
    name: "buku-besar",
    menu: {
        "buku-besar": {
            "buku-utang": {
                index: {
                    route: "/buku-besar/buku-utang",
                    page: BukuUtang,
                },
            },
            "buku-piutang": {
                index: {
                    route: "/buku-besar/buku-piutang",
                    page: BukuPiutang,
                },
            },
            "buku-besar-perakun": {
                index: {
                    route: "/buku-besar/buku-besar-perakun",
                    page: BukuBesarAkun,
                },
            },
        },
    },
};

export const goodsInTransitMenu: MenuApps["menus"][0] = {
    name: "goods-in-transit",
    menu: {
        payment: {
            index: {
                route: "/goods-in-transit",
                page: GoodsInTransit,
            },
        },
    },
};

export const accountingReportMenu: MenuApps["menus"][0] = {
    name: "report",
    menu: {
        report: {
            "neraca-saldo": {
                index: {
                    route: "/report/neraca-saldo",
                    page: ReportNeracaSaldo,
                },
            },
            "laba-rugi": {
                index: {
                    route: "/report/laba-rugi",
                    page: TestPage,
                },
            },
            neraca: {
                index: {
                    route: "/report/neraca",
                    page: TestPage,
                },
            },
        },
    },
};

export const purchasingRequestMenu: MenuApps["menus"][0] = {
    name: "purchasing-request",
    menu: {
        "purchasing-request": {
            index: {
                route: "/purchasing-request",
                page: PurchasingRequest,
            },
            add: {
                route: `/purchasing-request${ADD_PATH}`,
                page: PurchasingRequestAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/purchasing-request${EDIT_PATH}`,
                page: PurchasingRequestEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/purchasing-request${VIEW_PATH}`,
                page: PurchasingRequestView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const purchasingMenu: MenuApps["menus"][0] = {
    name: "purchasing-order",
    menu: {
        "purchasing-order": {
            index: {
                route: "/purchasing-order",
                page: PurchasingInventory,
            },
            add: {
                route: `/purchasing-order${ADD_PATH}`,
                page: PurchasingInventoryAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/purchasing-order${EDIT_PATH}`,
                page: PurchasingInventoryEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/purchasing-order${VIEW_PATH}`,
                page: PurchasingInventoryView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const grInventoryMenu: MenuApps["menus"][0] = {
    name: "gr-inventory",
    menu: {
        "gr-inventory": {
            index: {
                route: "/gr-inventory",
                page: GrInventory,
            },
            view: {
                route: `/gr-inventory${VIEW_PATH}`,
                page: GrInventoryView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const grSuppliesMenu: MenuApps["menus"][0] = {
    name: "gr-supplies",
    menu: {
        "gr-supplies": {
            index: {
                route: "/gr-supplies",
                page: PenerimaanBarang,
            },
            add: {
                route: `/gr-supplies${ADD_PATH}`,
                page: PenerimaanBarangAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/gr-supplies${VIEW_PATH}`,
                page: PenerimaanBarangView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const pemakaianSuppliesMenu: MenuApps["menus"][0] = {
    name: "pemakaian-supplies",
    menu: {
        "pemakaian-supplies": {
            index: {
                route: "/pemakaian-supplies",
                page: PemakaianBarang,
            },
            add: {
                route: `/pemakaian-supplies${ADD_PATH}`,
                page: PemakaianBarangAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/pemakaian-supplies${VIEW_PATH}`,
                page: PemakaianBarangView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const fvoucherMenu: MenuApps["menus"][0] = {
    name: "f-voucher",
    menu: {
        "f-voucher": {
            "receipt-voucher": {
                index: {
                    route: "/f-voucher/receipt-voucher",
                    page: Receipt,
                },
                add: {
                    route: `/f-voucher/receipt-voucher${ADD_PATH}`,
                    page: ReceiptAdd,
                    backHandlerOverride() {
                        history.back();
                    },
                },
                view: {
                    route: `/f-voucher/receipt-voucher${VIEW_PATH}`,
                    page: ReceiptView,
                    backHandlerOverride() {
                        history.back();
                    },
                },
                edit: {
                    route: `/f-voucher/receipt-voucher${EDIT_PATH}`,
                    page: ReceiptEdit,
                    backHandlerOverride() {
                        history.back();
                    },
                },
            },
            "payment-voucher": {
                index: {
                    route: "/f-voucher/payment-voucher",
                    page: Payment,
                },
                add: {
                    route: `/f-voucher/payment-voucher${ADD_PATH}`,
                    page: PaymentAdd,
                    backHandlerOverride() {
                        history.back();
                    },
                },
                view: {
                    route: `/f-voucher/payment-voucher${VIEW_PATH}`,
                    page: PaymentView,
                    backHandlerOverride() {
                        history.back();
                    },
                },
                edit: {
                    route: `/f-voucher/payment-voucher${EDIT_PATH}`,
                    page: PaymentEdit,
                    backHandlerOverride() {
                        history.back();
                    },
                },
            },
        },
        "f-transfer-bank": {
            index: {
                route: "/f-transfer-bank",
                page: TransferBank,
            },
            add: {
                route: `/f-transfer-bank${ADD_PATH}`,
                page: TransferBankAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/f-transfer-bank${VIEW_PATH}`,
                page: TransferBankView,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/f-transfer-bank${EDIT_PATH}`,
                page: TransferBankEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "transfer-bank-cabang": {
            index: {
                route: "/transfer-bank-cabang",
                page: TransferBankCabang,
            },
            add: {
                route: `/transfer-bank-cabang${ADD_PATH}`,
                page: TransferBankCabangAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/transfer-bank-cabang${VIEW_PATH}`,
                page: TransferBankCabangView,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/transfer-bank-cabang${EDIT_PATH}`,
                page: TransferBankCabangEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "f-history-bank": {
            index: {
                route: "/f-history-bank",
                page: HistoryBank,
            },
        },
    },
};

export const teamForceTaskMenu: MenuApps["menus"][0] = {
    name: "team-force",
    menu: {
        "team-force": {
            task: {
                index: {
                    route: "/team-force/task",
                    page: TeamForceTask,
                },
                view: {
                    route: `/team-force/task${VIEW_PATH}`,
                    page: TeamForceTaskView,
                    backHandlerOverride() {
                        history.back();
                    },
                },
            },
        },
    },
};

export const masterMenu: MenuApps["menus"][0] = {
    name: "master",
    menu: {
        "master-holiday": {
            task: {
                index: {
                    route: "/master/master-holiday",
                    page: MasterHoliday,
                },
            },
        },
    },
};

export const listOrderMenu: MenuApps["menus"][0] = {
    name: "list-order",
    menu: {
        "list-order": {
            index: {
                route: "/list-order",
                page: Order,
            },
            view: {
                route: `/list-order${VIEW_PATH}`,
                page: OrderView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "list-working-order": {
            index: {
                route: "/list-working-order",
                page: WorkingOrder,
            },
            view: {
                route: `/list-working-order${VIEW_PATH}`,
                page: WorkingOrderView,
            },
        },
    },
};

export const fastMovingProductMenu: MenuApps["menus"][0] = {
    name: "fast-moving-product",
    menu: {
        "fast-moving-product": {
            index: {
                route: "/fast-moving-product",
                page: FastMovingProduct,
            },
            view: {
                route: `/fast-moving-product${VIEW_PATH}`,
                page: FastMovingProductView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const listCancelOrderMenu: MenuApps["menus"][0] = {
    name: "list-cancel-order",
    menu: {
        "list-cancel-order": {
            index: {
                route: "/list-cancel-order",
                page: ListCancelOrder,
            },
            view: {
                route: `/list-cancel-order${VIEW_PATH}`,
                page: ListCancelOrderView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const rawMaterialMenu: MenuApps["menus"][0] = {
    name: "raw-material",
    menu: {
        "raw-material": {
            index: {
                route: "/raw-material",
                page: RawMaterial,
            },
            add: {
                route: `/raw-material${ADD_PATH}`,
                page: RawMaterialAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/raw-material${EDIT_PATH}`,
                page: RawMaterialEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const bomMenu: MenuApps["menus"][0] = {
    name: "bom",
    menu: {
        bom: {
            index: {
                route: "/bom",
                page: Bom,
            },
            add: {
                route: `/bom${ADD_PATH}`,
                page: BomAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/bom${EDIT_PATH}`,
                page: BomEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const assemblingMenu: MenuApps["menus"][0] = {
    name: "assembling",
    menu: {
        assembling: {
            index: {
                route: "/assembling",
                page: Assembling,
            },
            add: {
                route: `/assembling${ADD_PATH}`,
                page: AssemblingAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const masterVariableMenu: MenuApps["menus"][0] = {
    name: "master-variable",
    menu: {
        "master-variable": {
            index: {
                route: "/master-variable",
                page: MasterVariable,
            },
            add: {
                route: `/master-variable${ADD_PATH}`,
                page: MasterVariableAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/master-variable${EDIT_PATH}`,
                page: MasterVariableEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const builderMenu: MenuApps["menus"][0] = {
    name: "builder",
    menu: {
        builder: {
            index: {
                route: "/builder",
                page: ManufactureBuilder,
                hasOwnLayout: true,
            },
        },
    },
};

export const manufactureOrderMenu: MenuApps["menus"][0] = {
    name: "manufacture-order",
    menu: {
        "manufacture-order": {
            index: {
                route: "/manufacture-order",
                page: ManufacturingOrder,
                backHandlerOverride() {
                    history.back();
                },
            },
            add: {
                route: "/manufacture-order/add",
                page: ManufacturingOrderAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: "/manufacture-order/edit",
                page: ManufacturingOrderEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const purchasingOrderMMenu: MenuApps["menus"][0] = {
    name: "purchasing-order-m",
    menu: {
        "purchasing-order-m": {
            index: {
                route: "/purchasing-order-m",
                page: PurchasingOrderM,
                backHandlerOverride() {
                    history.back();
                },
            },
            add: {
                route: "/purchasing-order-m/add",
                page: PurchasingOrderMAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: "/purchasing-order-m/view",
                page: PurchasingOrderMView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const workingOrderMMenu: MenuApps["menus"][0] = {
    name: "working-order-m",
    menu: {
        "working-order-m": {
            index: {
                route: "/working-order-m",
                page: WorkingOrderM,
            },
            add: {
                route: `/working-order-m${ADD_PATH}`,
                page: WorkingOrderMAdd,
            },
            edit: {
                route: `/working-order-m${EDIT_PATH}`,
                page: WorkingOrderMEdit,
            },
            view: {
                route: `/working-order-m${VIEW_PATH}`,
                page: WorkingOrderMView,
            },
        },
    },
};

export const rekapMenu: MenuApps["menus"][number] = {
    name: "rekap-penjualan",
    menu: {
        autoloka: {
            index: {
                route: "/rekap-penjualan/autoloka",
                page: RekapAutoloka,
            },
        },
        offline: {
            index: {
                route: "/rekap-penjualan/offline",
                page: RekapOffline,
            },
        },
        online: {
            index: {
                route: "/rekap-penjualan/online",
                page: RekapOnline,
            },
        },
        "bp-sales": {
            index: {
                route: "/rekap-penjualan/bp-sales",
                page: RekapBpSales,
            },
        },
    },
};

export const rekapAccountingMenu: MenuApps["menus"][number] = {
    name: "rekap-penjualan-accounting",
    menu: {
        offline: {
            index: {
                route: "/rekap-penjualan-accounting/offline",
                page: RekapOfflineAccounting,
            },
        },
        online: {
            index: {
                route: "/rekap-penjualan-accounting/online",
                page: RekapOnlineAccounting,
            },
        },
    },
};

export const consignmentMenu: MenuApps["menus"][number] = {
    name: "internal-order",
    menu: {
        "generate-invoice": {
            index: {
                route: "/internal-order/generate-invoice",
                page: CGenerateInvoice,
            },
            add: {
                route: `/internal-order/generate-invoice${ADD_PATH}`,
                page: CGenerateInvoiceAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/internal-order/generate-invoice${VIEW_PATH}`,
                page: CGenerateInvoiceView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "purchase-order": {
            index: {
                route: "/internal-order/purchase-order",
                page: CPurchaseOrder,
            },
            view: {
                route: `/internal-order/purchase-order${VIEW_PATH}`,
                page: CPurchaseOrderView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "sales-order": {
            index: {
                route: "/internal-order/sales-order",
                page: CSalesOrder,
            },
            view: {
                route: `/internal-order/sales-order${VIEW_PATH}`,
                page: CSalesOrderView,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/internal-order/sales-order${EDIT_PATH}`,
                page: CSalesOrderEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "sales-order-outstanding": {
            index: {
                route: "/internal-order/sales-order-outstanding",
                page: CSalesOrderOutstanding,
            },
        },
        "picking-detail": {
            index: {
                route: "/internal-order/picking-detail",
                page: CPickingDetail,
            },
            add: {
                route: `/internal-order/picking-detail${ADD_PATH}`,
                page: CPickingDetailAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/internal-order/picking-detail${VIEW_PATH}`,
                page: CPickingDetailView,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/internal-order/picking-detail${EDIT_PATH}`,
                page: CPickingDetailEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "packing-list": {
            index: {
                route: "/internal-order/packing-list",
                page: CPackingList,
            },
            add: {
                route: `/internal-order/packing-list${ADD_PATH}`,
                page: CPackingListAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/internal-order/packing-list${VIEW_PATH}`,
                page: CPackingListView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "delivery-order": {
            index: {
                route: "/internal-order/delivery-order",
                page: CDeliveryOrder,
            },
            add: {
                route: `/internal-order/delivery-order${ADD_PATH}`,
                page: CDeliveryOrderAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/internal-order/delivery-order${VIEW_PATH}`,
                page: CDeliveryOrderView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        Invoice: {
            index: {
                route: "/internal-order/invoice",
                page: CInvoice,
            },
            view: {
                route: `/internal-order/invoice${VIEW_PATH}`,
                page: CInvoiceView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "return-order": {
            index: {
                route: "/internal-order/return-order",
                page: CReturnOrder,
            },
            add: {
                route: `/internal-order/return-order${ADD_PATH}`,
                page: CReturnOrderAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/internal-order/return-order${VIEW_PATH}`,
                page: CReturnOrderView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const hadirAppMenu: MenuApps["menus"][0] = {
    name: "perjalanan-dinas",
    menu: {
        "perjalanan-dinas": {
            index: {
                route: "/perjalanan-dinas",
                page: PerjalananDinas,
            },
            view: {
                route: `/perjalanan-dinas${VIEW_PATH}`,
                page: PerjalananDinasView,
            },
        },
        cuti: {
            index: {
                route: "/cuti",
                page: Cuti,
            },
            view: {
                route: `/cuti${VIEW_PATH}`,
                page: CutiView,
            },
        },
        lembur: {
            index: {
                route: "/lembur",
                page: Lembur,
            },
            view: {
                route: `/lembur${VIEW_PATH}`,
                page: LemburView,
            },
        },
    },
};

export const giiastargetMenu: MenuApps["menus"][0] = {
    name: "cashier-penjualan",
    menu: {
        "cashier-penjualan": {
            index: {
                route: "/cashier-penjualan",
                page: TestPage,
            },
        },
        "cashier-penjualan-dp": {
            index: {
                route: "/cashier-penjualan-dp",
                page: TestPage,
            },
        },
        "cashier-transaksi": {
            index: {
                route: "/cashier-transaksi",
                page: CashierTransaksi,
            },
            view: {
                route: `/cashier-transaksi${VIEW_PATH}`,
                page: CashierTransaksiView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

export const externalOrderMenu: MenuApps["menus"][number] = {
    name: "external-order",
    menu: {
        "sales-order": {
            index: {
                route: "/external-order/sales-order",
                page: ExtSalesOrder,
            },
            view: {
                route: `/external-order/sales-order${VIEW_PATH}`,
                page: ExtSalesOrderView,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/external-order/sales-order${EDIT_PATH}`,
                page: ExtSalesOrderEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
            add: {
                route: `/external-order/sales-order${ADD_PATH}`,
                page: ExtSalesOrderAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "sales-order-outstanding": {
            index: {
                route: "/external-order/sales-order-outstanding",
                page: ExtSalesOrderOutstanding,
            },
        },
        "purchase-order": {
            index: {
                route: "/external-order/purchase-order",
                page: ExtPurchaseOrder,
            },
            view: {
                route: `/external-order/purchase-order${VIEW_PATH}`,
                page: ExtPurchaseOrderView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "picking-detail": {
            index: {
                route: "/external-order/picking-detail",
                page: ExtPickingDetail,
            },
            add: {
                route: `/external-order/picking-detail${ADD_PATH}`,
                page: ExtPickingDetailAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/external-order/picking-detail${VIEW_PATH}`,
                page: ExtPickingDetailView,
                backHandlerOverride() {
                    history.back();
                },
            },
            edit: {
                route: `/external-order/picking-detail${EDIT_PATH}`,
                page: ExtPickingDetailEdit,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "packing-list": {
            index: {
                route: "/external-order/packing-list",
                page: ExtPackingList,
            },
            add: {
                route: `/external-order/packing-list${ADD_PATH}`,
                page: ExtPackingListAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/external-order/packing-list${VIEW_PATH}`,
                page: ExtPackingListView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "delivery-order": {
            index: {
                route: "/external-order/delivery-order",
                page: ExtDeliveryOrder,
            },
            add: {
                route: `/external-order/delivery-order${ADD_PATH}`,
                page: ExtDeliveryOrderAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/external-order/delivery-order${VIEW_PATH}`,
                page: ExtDeliveryOrderView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "good-receipt": {
            index: {
                route: "/external-order/good-receipt",
                page: ExtGoodsReceipt,
            },
            add: {
                route: `/external-order/good-receipt${ADD_PATH}`,
                page: ExtGoodsReceiptAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/external-order/good-receipt${VIEW_PATH}`,
                page: ExtGoodsReceiptView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        Invoice: {
            index: {
                route: "/external-order/invoice",
                page: ExtInvoice,
            },
            view: {
                route: `/external-order/invoice${VIEW_PATH}`,
                page: ExtInvoiceView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
        "return-order": {
            index: {
                route: "/external-order/return-order",
                page: ExtReturnOrder,
            },
            add: {
                route: `/external-order/return-order${ADD_PATH}`,
                page: ExtReturnOrderAdd,
                backHandlerOverride() {
                    history.back();
                },
            },
            view: {
                route: `/external-order/return-order${VIEW_PATH}`,
                page: ExtReturnOrderView,
                backHandlerOverride() {
                    history.back();
                },
            },
        },
    },
};

const apps: MenuApps[] = [
    {
        name: "autoloka",
        menus: [
            dashboardMenu,
            reportMenu,
            masterDataMenu,
            productMenu,
            warehouseMenu,
            printMenu,
            documentMenu,
            promoMenu,
            featureMenu,
            userManagementMenu,
            salesMenu,
            masterMenu,
            rekapMenu,
            consignmentMenu,
            externalOrderMenu,
        ],
    },
    {
        name: "pos",
        menus: [
            dashboardPosMenu,
            salesPosMenu,
            listOrderMenu,
            fastMovingProductMenu,
            listCancelOrderMenu,
            bpSalesPosMenu,
            promoMenu,
            userManagementMenu,
        ],
    },
    {
        name: "document",
        menus: [documentMenu],
    },
    {
        name: "inventory",
        menus: [masterDataMenu, productMenu, warehouseMenu, printMenu],
    },
    {
        name: "other",
        menus: [pdfConverterMenu],
    },
    {
        name: "company-asset",
        menus: [companyAssetMenu],
    },
    {
        name: "accounting",
        menus: [masterAccountingMenu, jurnalMenu, persendianMenu, bukuBesarMenu, goodsInTransitMenu, accountingReportMenu, rekapAccountingMenu],
    },
    {
        name: "finance",
        menus: [fvoucherMenu],
    },
    {
        name: "purchasing",
        menus: [purchasingRequestMenu, purchasingMenu, grInventoryMenu, grSuppliesMenu, pemakaianSuppliesMenu],
    },
    {
        name: "team-force",
        menus: [teamForceTaskMenu],
    },
    {
        name: "manufacture",
        menus: [
            rawMaterialMenu,
            bomMenu,
            manufactureOrderMenu,
            purchasingOrderMMenu,
            workingOrderMMenu,
            assemblingMenu,
            masterVariableMenu,
            builderMenu,
        ],
    },
    {
        name: "hadir-app",
        menus: [hadirAppMenu, masterDataMenu],
    },
    {
        name: "giias-target-app",
        menus: [giiastargetMenu],
    },
];

export default apps;
