/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-children-prop */
import { Button, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import ModalJournalDetail from "modules/master-data/purchasing/modal-journal-detail";
import ModalRefDetail from "modules/master-data/purchasing/modal-ref-voucher";
import moment from "moment";
import { HistoryBankItem } from "services/api-endpoints/accounting/history-bank";
import { FORMAT_DATE_TIME } from "utils/constant";

type Props<T> = {
    listData: T[];
};

const HistoryBankTable = <T extends HistoryBankItem>({ listData }: Props<T>) => {
    const columns: ColumnsType<T> = [
        {
            width: "50px",
            title: "No",
            dataIndex: "-",
            render: (text, record, i) => (
                <>{record.note === "TOTAL" || record.note === "SALDO AWAL" ? <p /> : <p className="capitalize m-0">{i + 1}</p>}</>
            ),
        },
        {
            width: "150px",
            title: "Date",
            dataIndex: "created_at",
            render: (text) => <p className="capitalize m-0">{text ? moment(text)?.format(FORMAT_DATE_TIME) : ""}</p>,
        },
        {
            title: "Description",
            dataIndex: "note",
            render: (text, record) => {
                if (record.note === "TOTAL" || record.note === "SALDO AWAL") return <p className="capitalize m-0">{text || "-"}</p>;
                const note = record.note || "";
                const title = note.length > 27 ? `${note.substring(0, 27)}...` : note;
                return (
                    <ModalRefDetail title="Detail Referensi" id={record.doc_id} type={record.doc_type}>
                        {(ctrl) => (
                            <Button onClick={ctrl.openModal} size="small" className="max-w-250px">
                                {title}
                            </Button>
                        )}
                    </ModalRefDetail>
                );
            },
        },
        {
            title: "Ref",
            dataIndex: "journal_no",
            render: (text, record) => {
                if (record.note === "TOTAL" || record.note === "SALDO AWAL") return <p> </p>;
                return (
                    <ModalJournalDetail title="Detail Jurnal" id={record.id}>
                        {(ctrl) => (
                            <Button onClick={ctrl.openModal} size="small">
                                {text}
                            </Button>
                        )}
                    </ModalJournalDetail>
                );
            },
        },
        {
            title: "Debit",
            dataIndex: "debit",
            render: (text, record) => (
                <>
                    {record.note === "TOTAL" || record.note === "SALDO AWAL" ? (
                        <p />
                    ) : (
                        <p className="font-semibold">{text !== 0 ? text?.ToIndCurrency("Rp") : "-"}</p>
                    )}
                </>
            ),
        },
        {
            title: "Kredit",
            dataIndex: "kredit",
            render: (text, record) => (
                <>
                    {record.note === "TOTAL" || record.note === "SALDO AWAL" ? (
                        <p />
                    ) : (
                        <p className="font-semibold">{text !== 0 ? text?.ToIndCurrency("Rp") : "-"}</p>
                    )}
                </>
            ),
        },
        {
            title: "Saldo",
            dataIndex: "saldo",
            render: (text: number, _) => (
                <>
                    {text < 0 ? (
                        <p className="font-semibold text-red-500">{`${text?.ToIndCurrency("Rp")}`} (K)</p>
                    ) : (
                        <p className="font-semibold">{text?.ToIndCurrency("Rp")} (D)</p>
                    )}
                </>
            ),
        },
        {
            title: "Status",
            dataIndex: "is_acc_posted",
            render: (value) => (value ? "Posted" : "Unposted"),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id!}
            size="small"
            columns={columns}
            dataSource={listData || []}
            className="w-full"
            pagination={{
                pageSize: 1000,
                showSizeChanger: false,
                hideOnSinglePage: true,
            }}
        />
    );
};

export default HistoryBankTable;
