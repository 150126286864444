/* eslint-disable no-shadow */
import { Alert, Button, Card, message, Skeleton, Space } from "antd";
import State from "components/common/state";
import ToolbarAction from "components/toolbar/action";
import Print from "modules/master-data/external-order/ext-ro/print";
import TableView from "modules/master-data/external-order/ext-ro/table-view";
import { useRef } from "react";
import { AiFillPrinter } from "react-icons/ai";
import { useMutation, useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import extReturnOrderService from "services/api-endpoints/dashboard/master-data/ext-return-order";
import { RETURN_ORDER_STATUS, SOURCE_EXTERNAL_ORDER, SOURCE_WITHOUT_INVOICE } from "utils/constant";

interface Props {
    paramId?: any;
    asPreview?: boolean;
}

const ExtReturnOrderView = ({ paramId, asPreview }: Props) => {
    const [searchParams] = useSearchParams();
    const id = asPreview ? paramId : searchParams.get("id");

    const detailQuery = useQuery(
        [extReturnOrderService.detail, id],
        async () => {
            return (await extReturnOrderService.Detail(id)).data.data;
        },
        {
            refetchInterval: false,
            refetchOnWindowFocus: false,
            enabled: !!id,
        }
    );

    const approveMutate = useMutation(async (id: string) => {
        return (await extReturnOrderService.Approve({ id })).data.data;
    });

    const letterRef = useRef<HTMLDivElement | null>(null);
    const handlePrintLetter = useReactToPrint({
        content: () => letterRef.current,
        documentTitle: `Return Order - ${detailQuery.data?.doc_no}`,
        pageStyle: "@page { }",
    });

    const onClickPrintHandler = () => {
        handlePrintLetter();
    };

    const onClickApprove = () => {
        approveMutate.mutateAsync(id as string).then(() => {
            detailQuery.refetch();
            message.success("Approved");
        });
    };

    return (
        <div className="w-full flex flex-col gap-6">
            <Print ref={letterRef} data={detailQuery.data || null} />
            <ToolbarAction
                title="detail return order"
                rightAddition={() => (
                    <Space>
                        {!detailQuery.data?.status_approve && detailQuery.data?.inv_type === SOURCE_WITHOUT_INVOICE && (
                            <Button loading={approveMutate.isLoading} onClick={onClickApprove} type="primary">
                                Approve
                            </Button>
                        )}
                        <Button onClick={onClickPrintHandler} type="default" className="!flex !items-center">
                            <AiFillPrinter className="m-0 mr-2" />
                            Print
                        </Button>
                    </Space>
                )}
            />
            <State data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                {(state) => (
                    <>
                        <State.Data state={state}>
                            <Card className="">
                                <div className="grid grid-cols-4">
                                    <p className="capitalize m-0">
                                        Document no <br />
                                        <span className="italic m-0">{detailQuery.data?.doc_no || "-"}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        BP Name <br />
                                        <span className="italic m-0">{detailQuery.data?.bp_name || "-"}</span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Source <br />
                                        <span className="italic m-0">
                                            {SOURCE_EXTERNAL_ORDER.find((status) => status.value === detailQuery.data?.inv_type)?.label || "-"}
                                        </span>
                                    </p>
                                    <p className="capitalize m-0">
                                        Reason to return <br />
                                        <span className="italic m-0">{detailQuery.data?.reason || "-"}</span>
                                    </p>
                                    <p className="capitalize m-0 mt-5">
                                        Type Return <br />
                                        <span className="italic m-0">{detailQuery.data?.impact_type || "-"}</span>
                                    </p>
                                    <p className="capitalize m-0 mt-5">
                                        Warehouse Return <br />
                                        <span className="italic m-0">{detailQuery.data?.warehouse_name || "-"}</span>
                                    </p>
                                    <p className="capitalize m-0 mt-5">
                                        Status <br />
                                        <span className="italic m-0">
                                            {RETURN_ORDER_STATUS.find((status) => status.value === detailQuery.data?.order_status)?.label || "-"}
                                        </span>
                                    </p>
                                </div>
                            </Card>
                        </State.Data>
                        <State.Loading state={state}>
                            <Skeleton paragraph={{ rows: 1 }} active />
                            <Skeleton paragraph={{ rows: 1 }} active />
                            <Skeleton paragraph={{ rows: 1 }} active />
                            <Skeleton paragraph={{ rows: 1 }} active />
                        </State.Loading>
                        <State.Error state={state}>
                            <Alert type="error" message={(detailQuery.error as any)?.message} />
                        </State.Error>
                    </>
                )}
            </State>
            <TableView fetcher={detailQuery} />
        </div>
    );
};

export default ExtReturnOrderView;
