/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-shadow */
import { Alert, Card, Skeleton, Tabs, TabsProps, Tag } from "antd";
import StateRender from "components/common/state-render";
import ToolbarAction from "components/toolbar/action";
import { TableBoxType } from "modules/working-order-m/add/table-box";
import HistoryProduct from "modules/working-order-m/view/history-product";
import PrintDetailWOM from "modules/working-order-m/view/print-detail";
import TableBoxView from "modules/working-order-m/view/table-box";
import TableProductsView from "modules/working-order-m/view/table-product";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import workingOrderMService, { ProductPOM } from "services/api-endpoints/dashboard/manufacture/working-order-m";
import { FORMAT_DATE_TIME, RESOURCE_OR_PLANNING, STATUS_WOM } from "utils/constant";

const WorkingOrderMView = () => {
    const [params] = useSearchParams();
    const id = params.get("id") as any;

    const printRef = React.useRef<HTMLDivElement | null>(null);

    const handlePrintDetail = useReactToPrint({
        content: () => printRef.current,
        documentTitle: `Working order M`,
        pageStyle: "@page { size: A5 landscape; }",
    });

    const [products, setProducts] = React.useState<Partial<ProductPOM>[]>([]);
    const [box, setBox] = React.useState<TableBoxType[]>([]);
    const [tab, setTab] = React.useState<string>("products");
    const [tabProd, setTabProd] = React.useState<string>("");
    const [printBox, setPrintBox] = React.useState<TableBoxType | null>(null);

    const detailQuery = useQuery(
        [workingOrderMService.detail, id],
        async () => {
            return (await workingOrderMService.Detail({ id })).data.data;
        },
        {
            enabled: !!id,
            onSuccess(data) {
                setProducts(data?.list_product?.map((p) => ({ product_id: p.product_id, product_code1: p.product_code1, qty: p.qty })) || []);
                const temp = data?.list_box?.map((el, i) => ({
                    ...el,
                    no: i + 1,
                })) as TableBoxType[];
                setBox(temp || []);
                setTabProd(String(data?.list_product[0].product_id));
            },
        }
    );

    const onChange = (key: string) => {
        setTab(key);
    };

    const onChangeProd = (key: string) => {
        setTabProd(key);
    };

    const onClickPrint = (bx: TableBoxType) => {
        setPrintBox(bx);
        setTimeout(() => handlePrintDetail(), 300);
    };

    const itemsProduct: TabsProps["items"] = [
        {
            key: "products",
            label: "Products",
            children: <TableProductsView id={id} list={products as ProductPOM[]} action={false} pagination={false} />,
        },
    ];

    const itemProd: TabsProps["items"] = products.map((val, i) => {
        const mId = String(val.product_id);
        return {
            key: mId,
            label: val.product_code1,
            children: <HistoryProduct id={mId} />,
        };
    });

    const items: TabsProps["items"] = [
        {
            key: "products",
            label: "Products",
            children: <TableProductsView id={id} list={products as ProductPOM[]} action={false} pagination={false} />,
        },
        {
            key: "box",
            label: "Box",
            children: !box.length ? (
                <Alert message="Silakan add box dengan akun role `IPQC`" type="warning" showIcon />
            ) : (
                <TableBoxView id={id} onClickPrint={onClickPrint} list={box} action={false} pagination={false} />
            ),
        },
        // {
        //     key: "history",
        //     label: "History",
        //     children: <Tabs type="card" activeKey={tabProd} items={itemProd} onChange={onChangeProd} />,
        // },
    ];

    const colorTag = ["red", "blue", "green"];

    return (
        <div className="w-full flex flex-col gap-6">
            <PrintDetailWOM box={printBox} ref={printRef} data={detailQuery.data} />
            <ToolbarAction title="detail working order m" />
            <StateRender data={detailQuery.data} isLoading={detailQuery.isLoading} isError={detailQuery.isError}>
                <StateRender.Data>
                    <Card>
                        <div className="w-full grid grid-cols-5 gap-4">
                            <p className="capitalize m-0">
                                Doc No <br />
                                <span className="m-0 underline">{detailQuery.data?.wo_no}</span>
                            </p>
                            <p className="capitalize m-0">
                                Resource/Planning <br />
                                <span className="m-0 underline">
                                    {RESOURCE_OR_PLANNING.find((el) => el.value === detailQuery.data?.resource_id)?.label}
                                </span>
                            </p>
                            <p className="capitalize m-0">
                                Ref Doc No <br />
                                <span className="m-0 underline">{detailQuery.data?.po_no}</span>
                            </p>
                            <p className="capitalize m-0">
                                Note <br />
                                <span className="m-0 underline">{detailQuery.data?.note || "-"}</span>
                            </p>
                            <p className="capitalize m-0">
                                Status <br />
                                <Tag color={colorTag[detailQuery.data?.status || 1]}>
                                    {STATUS_WOM.find((val) => val.value === (detailQuery.data?.status || 1))?.label}
                                </Tag>
                            </p>
                            <p className="capitalize m-0">
                                Created At <br />
                                <span className="m-0 underline">{moment(detailQuery.data?.created_at).format(FORMAT_DATE_TIME)}</span>
                            </p>
                        </div>
                    </Card>
                    <Card>
                        <Tabs activeKey={tab} items={items} onChange={onChange} />
                    </Card>
                </StateRender.Data>
                <StateRender.Loading>
                    <Skeleton className="w-[80%] h-[30px] mb-5" />
                    <Skeleton className="w-[70%] h-[30px] mb-5" />
                    <Skeleton className="w-[30%] h-[30px] mb-5" />
                </StateRender.Loading>
                <StateRender.Error>
                    <Alert message={(detailQuery.error as any)?.message} />
                </StateRender.Error>
            </StateRender>
        </div>
    );
};

export default WorkingOrderMView;
