import { Icon } from "@mui/material";
import { Alert, Skeleton, StepProps, Steps } from "antd";
import State from "components/common/state";
import ModalTemplate, { Props as ModalProps } from "components/modal/template-modal";
import moment from "moment";
import { useCallback, useRef } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useQuery } from "react-query";
import workingOrderMService from "services/api-endpoints/dashboard/manufacture/working-order-m";
import { FORMAT_DATE_TIME } from "utils/constant";

type Props = ModalProps & {
    id: any;
    boxNo: any;
    productId: any;
    productName: any;
};

const ModalHistoryBox = ({ id, boxNo, productId, productName, children, ...props }: Props) => {
    const closeRef = useRef<HTMLButtonElement | null>(null);

    const historyBoxQuery = useQuery([workingOrderMService.historyBox, productId], async () => {
        return (await workingOrderMService.HistoryBox({ id, product_id: productId, box_no: boxNo })).data.data;
    });

    const histories = useCallback(
        () =>
            historyBoxQuery.data?.map(
                (history) =>
                    ({
                        status: "finish",
                        icon: <Icon fontSize="medium">{history.icon || "beenhereicon"}</Icon>,
                        title: <span className="font-semibold p-0 m-0">{history.note || "-"}</span>,
                        description: (
                            <div className="flex items-center mb-2">
                                <IoIosCheckmarkCircle className="text-green-300 text-xl" />
                                <div className="ml-2">
                                    <p className="m-0 font-normal capitalize text-black">
                                        {history.created_by}, {history.description}
                                    </p>
                                    <p className="m-0 capitalize">{moment(history.created_at).format(FORMAT_DATE_TIME)}</p>
                                </div>
                            </div>
                        ),
                    } as StepProps)
            ),
        [historyBoxQuery.data]
    );

    return (
        <ModalTemplate
            title={`History Product (${productName})`}
            handlerInComponent={historyBoxQuery.isLoading ? () => <p>Getting History data...</p> : children}
            width={900}
            footer={null}
            {...props}
        >
            {(dt) => (
                <>
                    <button ref={closeRef} type="button" className="hidden" onClick={dt.closeModal}>
                        close
                    </button>
                    <State data={historyBoxQuery.data} isLoading={historyBoxQuery.isLoading} isError={historyBoxQuery.isError}>
                        {(state) => (
                            <>
                                <State.Data state={state}>
                                    <Steps direction="vertical" size="default" items={histories() || []} />
                                </State.Data>
                                <State.Loading state={state}>
                                    <Skeleton active paragraph={{ rows: 3 }} />
                                </State.Loading>
                                <State.Error state={state}>
                                    <Alert message={(historyBoxQuery.error as any)?.message} type="error" />
                                </State.Error>
                            </>
                        )}
                    </State>
                </>
            )}
        </ModalTemplate>
    );
};

export default ModalHistoryBox;
