/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import { Button, Tag } from "antd";
import { ColumnType } from "antd/lib/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import React from "react";
import { AiFillPrinter, AiOutlineHistory } from "react-icons/ai";
import { TableBoxType } from "../add/table-box";
import ModalHistoryBox from "./modal-history-box";

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow"> & {
    onClickPrint?: (box: TableBoxType) => void;
    id: any;
};

export function addTable<T extends TableBoxType>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ setList, list, onClickPrint, id, ...props }: Props<T>) => {
        const [editRow, setEditRow] = React.useState<TableBoxType | null>(null);

        const columns: ColumnType<TableBoxType>[] = [
            {
                title: "No",
                dataIndex: "no",
                render: (t, r, i) => i + 1,
            },
            {
                title: "Box No",
                dataIndex: "box_no",
            },
            {
                title: "Machine No",
                dataIndex: "machine_no",
            },
            {
                title: "Operator",
                dataIndex: "operator_name",
            },
            {
                title: "Register Status",
                dataIndex: "is_regis",
                render: (text) => <Tag color={text === 1 ? "green" : "gray"}>{text === 1 ? "Done" : "Not Yet"}</Tag>,
            },
            {
                title: "Product Code",
                render: (val, record) => (
                    <div className="flex flex-col gap-1">
                        {record?.detail?.map((product) => (
                            <p className="m-0">{product?.product_code1}</p>
                        ))}
                    </div>
                ),
            },
            {
                title: "Qty",
                render: (val, record) => (
                    <div className="flex flex-col gap-1">
                        {record?.detail?.map((product) => (
                            <p className="m-0">{product?.qty}</p>
                        ))}
                    </div>
                ),
            },
            {
                title: "History",
                render: (val, record) => (
                    <div>
                        {record?.detail?.map((product) => (
                            <ModalHistoryBox id={id} boxNo={record.box_no} productId={product.product_id} productName={product.product_code1}>
                                {(dt) => (
                                    <Button onClick={dt.openModal} type="default" className="!flex !items-center">
                                        <AiOutlineHistory className="m-0 mr-2" />
                                        History Product
                                    </Button>
                                )}
                            </ModalHistoryBox>
                        ))}
                    </div>
                ),
            },
            {
                title: "Print Box",
                render: (val, record) => (
                    <Button
                        onClick={() => {
                            if (onClickPrint) {
                                onClickPrint(record);
                            }
                        }}
                        type="default"
                        className="!flex !items-center"
                    >
                        <AiFillPrinter className="m-0 mr-2" />
                        Print Box
                    </Button>
                ),
            },
        ];

        const isEditing = (record: TableBoxType, edited: TableBoxType | null) => record.id === edited?.id && record.id === edited?.id;
        const findIndexSave = (record: TableBoxType, edited: TableBoxType) => record.id === edited?.id && record.id === edited.id;
        const rowKey = (record: TableBoxType) => `${record.id}_${record?.id}`;

        const editInputType: EditTableProps<TableBoxType>["editInputType"] = {
            //
        };

        return (
            <Component
                {...props}
                bordered
                list={list as any}
                setEditRow={setEditRow as any}
                editRow={editRow as any}
                editInputType={editInputType}
                rowKey={rowKey as any}
                columns={columns as any}
                isEditing={isEditing as any}
                findIndexSave={findIndexSave as any}
            />
        );
    };
}

const TableBoxView = addTable(EditTable);
export default TableBoxView;
