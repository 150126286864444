/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
import { Button } from "antd";
import { ColumnType } from "antd/lib/table";
import EditTable, { Props as EditTableProps } from "components/table/edit-table";
import { ProductWithBOM } from "pages/purchasing-order-m/view";
import React from "react";
import { BOM, LocatorManufacturingOrder } from "services/api-endpoints/dashboard/manufacture/manufacturing-order";

export type BOMWithLocatorAndNote = BOM &
    LocatorManufacturingOrder & {
        note?: any;
    };

type Props<T> = Omit<EditTableProps<T>, "isEditing" | "findIndexSave" | "rowKey" | "editInputType" | "columns" | "editRow" | "setEditRow">;

export type TransformListToBOMCentris = BOMWithLocatorAndNote & {
    product: ProductWithBOM;
    numberChild: number;
    qty?: number;
    stock?: number;
    hideRemoveAction?: boolean;
};

export function addTable<T extends ProductWithBOM>(Component: React.ComponentType<EditTableProps<T>>) {
    return ({ setList, list, ...props }: Props<T>) => {
        const [editRow, setEditRow] = React.useState<TransformListToBOMCentris | null>(null);
        const [page, setPage] = React.useState(1);

        const transformList = list.reduce((arr: TransformListToBOMCentris[], product: ProductWithBOM) => {
            const mapping = product.child?.map((child, i) => ({
                ...child,
                product,
                no: product.no,
                numberChild: i,
                hideRemoveAction: i !== 0,
            })) as TransformListToBOMCentris[];
            return [...arr, ...mapping];
        }, []);

        const childColumns: ColumnType<TransformListToBOMCentris>[] = [
            {
                title: "Code WIP",
                dataIndex: "code1",
            },
            {
                width: "100px",
                title: "Quantity Order",
                dataIndex: "qty_bom",
            },
            {
                width: "100px",
                title: "Stock",
                dataIndex: "stock",
            },
        ];

        const columns: ColumnType<TransformListToBOMCentris>[] = [
            {
                title: "No",
                width: "50px",
                render: (_, record) => (record.product.no || 0) + 1,
                onCell: (record) => {
                    const length = record.product?.child?.length;
                    return {
                        rowSpan: record.numberChild === 0 ? length : 0,
                    };
                },
            },
            {
                title: "Code 1",
                render: (_, record) => record.product.product_code1,
                onCell: (record) => {
                    const length = record.product?.child?.length;
                    return {
                        rowSpan: record.numberChild === 0 ? length : 0,
                    };
                },
            },
            ...childColumns,
        ];

        const isEditing = (record: TransformListToBOMCentris, edited: TransformListToBOMCentris | null) =>
            record.product_id === edited?.product_id && record.product.product_id === edited?.product?.product_id;
        const findIndexSave = (record: TransformListToBOMCentris, edited: TransformListToBOMCentris) =>
            record.product_id === edited?.product_id && record.product.product_id === edited?.product?.product_id;
        const rowKey = (record: TransformListToBOMCentris) => `${record.product_id}_${record.product?.product_id}`;

        const editInputType: EditTableProps<TransformListToBOMCentris>["editInputType"] = { qty: "number" };

        return (
            <Component
                {...props}
                bordered
                list={transformList as any}
                setEditRow={setEditRow as any}
                editRow={editRow as any}
                editInputType={editInputType}
                rowKey={rowKey as any}
                columns={columns as any}
                isEditing={isEditing as any}
                findIndexSave={findIndexSave as any}
                rowClassName={(record, index) => {
                    return (record.no || 0) % 2 === 0 ? "bg-ganjil" : "";
                }}
            />
        );
    };
}

const TablePOMView = addTable(EditTable);
export default TablePOMView;
