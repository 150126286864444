import TableTransferByDoc from "components/table/transfer-by-doc";
import { SelectOptionCustom } from "modules/master-data/price/lib/warehouse-select";
import React from "react";
import { UseQueryResult } from "react-query";
import { LocatorData } from "services/api-endpoints/dashboard/master-data/c-picking-detail";
import { ProductTransfer, ProductTransferWithEdit } from "services/api-endpoints/dashboard/master-data/material-movement";
import TableTransferEdit from "./table-direct-edit";
import { TYPE_MM_WORKING_ORDER } from "utils/constant";

export interface Props<T> {
    locatorFetcher: UseQueryResult<SelectOptionCustom[], unknown>;
    products?: ProductTransferWithEdit[];
    setProducts?: React.Dispatch<React.SetStateAction<ProductTransferWithEdit[]>>;
    setTargetKeys: React.Dispatch<React.SetStateAction<any[]>>;
    titles?: string[];
    loading?: boolean;
    targetKeys: string[];
    option?: number;
    bTypeId?: number;
    warehouseId?: number;
}

const TableTransferProductByOrder = <T extends ProductTransfer>({
    products,
    loading,
    setProducts,
    targetKeys,
    setTargetKeys,
    locatorFetcher,
    titles,
    option,
    bTypeId,
    warehouseId,
}: Props<T>) => {
    const onChange = (nextTargetKeys: string[]) => {
        setTargetKeys(nextTargetKeys);
    };

    const onEditChange = (record: T) => {
        if (setProducts) {
            setProducts((prev) => prev?.map((el) => (el?.random_id === record?.random_id ? record : el)));
        }
    };

    const getLocatorTo = (item: any) => {
        return locatorFetcher?.data?.find((loc) => loc?.value === item?.locator_to || loc?.label === item?.locator_to)?.label || "";
    };

    const onSubmitLocator = (docId: number, locator: LocatorData) => {
        if (setProducts) {
            setProducts((prev) =>
                [...prev].map((prd) => {
                    if (prd.product_id !== locator.product_id || prd.id !== docId) return prd;
                    return {
                        ...prd,
                        area_id: locator.area_id,
                        area_name: locator.area_name,
                        qty_locator: Number(locator.qty_locator),
                        ...(option === TYPE_MM_WORKING_ORDER ? { qty_use: locator.qty_locator } : {}),
                    };
                })
            );
        }
    };

    return (
        <TableTransferByDoc
            titles={titles}
            rowKey={(record) => record.random_id}
            dataSource={products || []}
            targetKeys={targetKeys}
            setProducts={setProducts}
            showSearch
            loading={loading}
            onChange={onChange}
            onSubmitLocator={onSubmitLocator}
            option={option}
            bTypeId={bTypeId}
            warehouseId={warehouseId}
            filterOption={(inputValue, item) =>
                item?.product_name?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) !== -1 ||
                item?.product_code?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) !== -1
            }
        >
            {({ filteredItems, rowSelection }) => {
                const tFiltered = filteredItems?.map((el: any) => ({
                    ...el,
                    locator_to: getLocatorTo(el),
                }));
                return (
                    <TableTransferEdit
                        locatorFetcher={locatorFetcher}
                        onEditChange={onEditChange}
                        dataSource={tFiltered}
                        rowSelection={rowSelection}
                        loading={loading}
                    />
                );
            }}
        </TableTransferByDoc>
    );
};

export default TableTransferProductByOrder;
